import {
  Box,
  Typography,
  useTheme,
  Grid,
  Button,
  Select,
  OutlinedInput,
  MenuItem,
  FormHelperText,
  TextField,
} from "@mui/material";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useState, useCallback } from "react";
import SuccessPopup from "../../../components/Common/SuccessPopup";
import { useNavigate } from "react-router-dom";
import { CustomInputLabel } from "../../../components/Common/Inputs/InputLabel";
import {
  DatePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc"; // Import UTC plugin
import timezone from "dayjs/plugin/timezone"; // Import timezone plugin

import {
  getAllCampaignAdminList,
  SET_TOAST,
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
} from "../../../store/actions";
import { useEffect } from "react";
import httpClient from "@utils/httpClient";

// Extend dayjs with plugins
dayjs.extend(utc);
dayjs.extend(timezone);
export default function SendEmailStep5({
  onNext,
  onBack,
  step,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const state = useSelector((state) => state);
  const theme = useTheme();
  const step1Details = state?.sendEmailCommunicate?.step1Details;
  const step2Details = state?.sendEmailCommunicate?.step2Details;
  const step3Details = state?.sendEmailCommunicate?.step3Details;
  const step4Details = state?.sendEmailCommunicate?.step4Details;
  const step5Details = state?.sendEmailCommunicate?.step5Details;

  const today = dayjs();
  const yesterday = dayjs().subtract(1, "day");
  const todayStartOfTheDay = today.startOf("day");

  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [confirmEmails, setConfirmEmails] = useState([]);
  const [campaignId, setCampaignId] = useState(step4Details?.campaignId ?? "");

  const getCurrentTime = () => {
    const now = dayjs().tz("America/New_York");
    let hours = now.hour();
    const minutes = now.minute().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Convert midnight (0 hours) to 12 AM
    return dayjs(
      `${now.format("YYYY-MM-DD")}T${hours}:${minutes} ${ampm}`,
      "YYYY-MM-DDTHH:mm A",
      //"America/New_York",
    );
  };

  const [minTime, setMinTime] = useState(getCurrentTime());

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const selectionInputStyle = {
    width: "100%",
    minWidth: "100%",
    padding: "12px 14px",
  };
  const ITEM_HEIGHT = 40;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };

  useEffect(() => {
    getAllCampaignAdmins();
  }, []);

  const validationSchema = Yup.object({
    confirmationEmail: Yup.string().required("Confirmation email is required"),
  });

  const formik = useFormik({
    initialValues: {
      confirmationEmail: step5Details?.confirmationEmail ?? "",
      scheduleDate:
        step5Details?.scheduleDate ?? dayjs().tz("America/New_York"),
      scheduleTime: step5Details?.scheduleTime ?? getCurrentTime(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (
        values?.scheduleDate.format("YYYY-MM-DD") ===
        dayjs().tz("America/New_York").format("YYYY-MM-DD")
      ) {
        const currentTime = getCurrentTime().format("HH:mm A");
        if (values?.scheduleTime.format("HH:mm A") < currentTime) {
          dispatch({
            type: SET_SUCCESS_ERROR_POPUP,
            data: {
              open: true,
              type: "error",
              message: "Time must be current or future",
            },
          });
        } else {
          if (campaignId) {
            sendCampaign(campaignId);
          } else {
            sendDraftCampaign();
          }
        }
      } else {
        if (campaignId) {
          sendCampaign(campaignId);
        } else {
          sendDraftCampaign();
        }
      }
    },
  });

  const handleBack = async () => {
    dispatch({
      type: "EMAIL_STEP_4_DETAILS",
      data: {
        campaignId: campaignId ?? "",
        emailBody: step4Details?.emailBody,
        emailTitle: step4Details?.emailTitle,
      },
    });

    await new Promise((resolve) => setTimeout(resolve, 0));
  
    const step5data = formik?.values;

    dispatch({
      type: "EMAIL_STEP_5_DETAILS",
      data: {
        ...step5data,
      },
    });
    await new Promise((resolve) => setTimeout(resolve, 0));
  
    onBack();
  };

  const sendDraftCampaign = async () => {
    const bodyParam = {
      Name: "Sketchplay-" + step1Details?.campaign_name,
      Subject: step2Details?.subject,
      FromName: step2Details?.fromName,
      FromEmail: step2Details?.fromEmail,
      ReplyTo: step2Details?.replyTo,
      ListIDs: step3Details?.ListIDs ?? [],
      TemplateID: step2Details?.template,
      TemplateContent: {
        Singlelines: [
          {
            Content: step4Details?.emailTitle ?? "",
            // "Href": "http://example.com/"
          },
        ],
        Multilines: [
          {
            Content: step4Details?.emailBody ?? "<p></p>",
          },
        ],
      },
    };
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.post("/draftCampaign", bodyParam);
    dispatch({ type: SET_LOADER, data: false });
    if (res && res.ok) {
      setCampaignId(res?.data);
      sendCampaign(res?.data);
    } else {
      if (res?.code === 303) {
        dispatch({
          type: SET_TOAST,
          data: {
            type: "error",
            message: res?.message ?? "Something went wrong",
          },
        });
        onNext(1);
      } else {
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message: res.message ?? "Something went wrong",
          },
        });
      }
    }
  };

  const sendCampaign = async (campaignId) => {
    const scheduleFormatDate = dayjs(formik?.values?.scheduleDate).format(
      "YYYY-MM-DD",
    );
    const scheduleFormatTime = dayjs(formik?.values?.scheduleTime).format(
      "HH:mm A",
    );
    const bodyParam = {
      id: campaignId,
      //ConfirmationEmail: "kartik.solanki@mobmaxime.com",
      ConfirmationEmail: formik?.values?.confirmationEmail,
      SendDate: `${scheduleFormatDate} ${scheduleFormatTime}`,
    };
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.post("/sendCampaign", bodyParam);
    dispatch({ type: SET_LOADER, data: false });
    if (res && res.ok) {
      setCampaignId("");
      setShowSuccessPopup(true);
      dispatch({ type: "RESET_CAMPAIGN_DATA" });
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: res.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleDateChange = (newDate) => {
    formik.setFieldValue("scheduleDate", newDate);
    setMinTime(getMinTime(newDate));
  };

  const getMinTime = (selectedDate) => {
    const currentDate = dayjs().tz("America/New_York");
    if (selectedDate.isSame(currentDate, "day")) {
      // If the selected date is the current date, set the minimum time to the current time
      return getCurrentTime();
    } else {
      // Otherwise, allow any time
      return dayjs(
        `${selectedDate.startOf("day").format("YYYY-MM-DDTHH:mm A")}`,
        // "America/New_York",
      );
    }
  };

  const getAllCampaignAdmins = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await getAllCampaignAdminList(dispatch, params);
    if (response?.ok) {
      setConfirmEmails(response?.data ?? []);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  return (
    <>
      <Box p={6}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={2} sx={{ width: "50%" }}>
            <Grid item lg={12} md={12} xs={12}>
              <CustomInputLabel sx={inputLabelStyle}>
                User Confirmation Email
              </CustomInputLabel>
              <Select
                name="confirmationEmail"
                onChange={formik.handleChange}
                // onBlur={formik.handleBlur}
                value={formik.values.confirmationEmail}
                input={<OutlinedInput sx={selectionInputStyle} />}
                MenuProps={MenuProps}
                inputProps={{
                  "aria-label": "Without label",
                  style: {
                    width: "100%",
                  },
                }}
                sx={{
                  width: "100%",
                  //padding: "0 0 0 110px",
                  "& .MuiOutlinedInput-root": {
                    minWidth: "100%",
                    width: "100%",
                    boxSizing: "border-box",
                  },
                  "& .MuiOutlinedInput-input": {
                    padding: "12px 14px",
                    minWidth: "100%",
                    width: "100%",
                    boxSizing: "border-box",
                  },
                }}
                displayEmpty
                renderValue={(value) => {
                  if (value) {
                    let confirmmail = confirmEmails?.find(
                      (t) => t?.EmailAddress === value,
                    );
                    if (confirmmail) {
                      return confirmmail?.Name;
                    }
                  } else {
                    return (
                      <Typography
                        variant="p"
                        fontWeight={400}
                        fontSize={14}
                        sx={{ opacity: 0.5 }}
                      >
                        Select confirmation Email...
                      </Typography>
                    );
                  }
                }}
              >
                {confirmEmails?.map((confirmmail) => (
                  <MenuItem
                    key={confirmmail?.EmailAddress}
                    value={confirmmail?.EmailAddress}
                  >
                    {confirmmail?.Name}
                  </MenuItem>
                ))}
              </Select>
              {Boolean(
                formik.touched.confirmationEmail &&
                  formik.errors.confirmationEmail,
              ) && (
                <FormHelperText error>
                  {formik.errors.confirmationEmail}
                </FormHelperText>
              )}
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <CustomInputLabel sx={inputLabelStyle}>
                Schedule Email Date
              </CustomInputLabel>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  disablePast
                  name="scheduleDate"
                  onChange={handleDateChange}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: false,
                    },
                  }}
                  value={formik.values.scheduleDate}
                  views={["year", "month", "day"]}
                  sx={{
                    "&.MuiFormControl-root": {
                      width: "100%",
                    },
                  }}
                />
              </LocalizationProvider>
              {Boolean(
                formik.touched.scheduleDate && formik.errors.scheduleDate,
              ) && (
                <FormHelperText error>
                  {formik.errors.scheduleDate}
                </FormHelperText>
              )}
            </Grid>
            <Grid item lg={6} md={12} xs={12}>
              <CustomInputLabel sx={inputLabelStyle}>
                Schedule Email Time
              </CustomInputLabel>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                timeZone="America/New_York"
              >
                <TimePicker
                  onChange={(newVal) =>
                    formik.setFieldValue("scheduleTime", newVal)
                  }
                  value={formik.values.scheduleTime}
                  minTime={minTime}
                  sx={{
                    width: "100%",
                    "& .MuiOutlinedInput-input": { py: 1.35 },
                  }}
                />
              </LocalizationProvider>
              {Boolean(
                formik.touched.scheduleTime && formik.errors.scheduleTime,
              ) && (
                <FormHelperText error>
                  {formik.errors.scheduleTime}
                </FormHelperText>
              )}
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} mt={6}>
              <Box
                display="flex"
                justifyContent="space-between"
                flexWrap="wrap"
                width={1}
                mt={5}
              >
                <Typography
                  variant="h5"
                  sx={{
                    maxWidth: 120,
                    height: 40,
                    background: "transparent",
                    border: `2px solid ${theme?.palette?.secondary?.dark}`,
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: theme?.palette?.secondary?.dark,
                    cursor: "pointer",
                    mr: 2,
                    my: 1,
                    px: 3,
                  }}
                  onClick={() => setShowCancelConfirmPopup(true)}
                >
                  Cancel
                </Typography>
                <Box display="flex" alignItems="center" flexWrap="wrap">
                  <Typography
                    variant="h5"
                    sx={{
                      maxWidth: 120,
                      height: 40,
                      background: "transparent",
                      border: `2px solid ${theme?.palette?.secondary?.dark}`,
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: theme?.palette?.secondary?.dark,
                      cursor: "pointer",
                      px: 3,
                      my: 1,
                    }}
                    onClick={handleBack}
                  >
                    Back
                  </Typography>
                  <Button
                    size="large"
                    variant="contained"
                    type="submit"
                    my={1}
                    sx={{
                      width: 150,
                      height: 45,
                      fontWeight: 700,
                      background: theme?.palette?.inputsLabels?.green,
                      color: "white",
                      ml: 2,
                      px: 3,
                      my: 1,
                    }}
                  >
                    Send Now
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      {showSuccessPopup && (
        <SuccessPopup
          open={true}
          handleClose={() => {
            setShowSuccessPopup(false);
            navigate(-1);
          }}
          title="Send Email"
          secondaryTitle="Success! You have successfully sent your email."
          isShowCloseBtn={true}
          isShowSubTitle={false}
          secondaryMessage="Tap anywhere out of this popup to hide this popup"
          alignCenterSecondaryTitle={true}
        />
      )}
    </>
  );
}

SendEmailStep5.modules = {
  toolbar: [
    [{ header: [1, 2, false] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link"],
    [{ color: [] }],
    [{ clipboard: true, paste: true }],
  ],
};
SendEmailStep5.formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "color",
];

import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Popover,
  Select,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  SET_LOADER,
  SET_SUCCESS_ERROR_POPUP,
  SET_TOAST,
  addUpdateAdminMessageAction,
  deleteAdminMessageAction,
  getAllMessagesAction,
} from "../../store/actions";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessPopup from "../../components/Common/SuccessPopup";
import CustomTextField from "../../components/CustomTextField";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import ActionCard from "../../components/Common/ActionCard";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";
import { useNavigate } from "react-router-dom";
import { Send_Email_Communicate } from "../../routes/constants";
import { charValidate } from "@utils/functions";
import CustomSwitch from "../../components/Common/Inputs/Switch";
import { LIMIT_VALIDATION } from "../../utils/constants";
import { formatDate } from "../../utils/functions";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const validationSchema = Yup.object({
  messageName: Yup.string().trim().required("Message name is required"),
  themeColor: Yup.string().required("Theme color selection is required"),
  message: Yup.string()
    .max(250, "Max character limit is 250")
    .trim()
    .required("Message is required"),
  expireDate: Yup.mixed().required("Expire date selection is required"),
  linkURL: Yup.string().matches(
    LIMIT_VALIDATION.UrlFormat,
    "URL format is invalid, enter correct URL",
  ),
  messageFormatType: Yup.string().required(
    "Message type selection is required",
  ),
});

const AdminMessage = () => {
  const tableRef = useRef();
  const [messageList, setMessagesList] = useState();
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [checkCommunity, setCheckCommunity] = useState(
    selectedDataGridRow?.permission?.community ?? false,
  );
  const [checkPost, setCheckPost] = useState(
    selectedDataGridRow?.permission?.post ?? false,
  );
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const theme = useTheme();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const openPopover = Boolean(popoverAnchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const [activeInactiveMessage, setActiveInactiveMessage] = useState(true);
  const [selectedPlacement, setSelectedPlacement] = useState([]);
  const [messagePlacementList, setMessagePlacementList] = useState([
    "Dashboard Middle Top",
  ]);
  const [
    showMessagePlacementSelectionRequiredError,
    setShowMessagePlacementSelectionRequiredError,
  ] = useState(false);
  const [
    confirmPopupActiveInactiveMessage,
    setConfirmPopupActiveInactiveMessage,
  ] = useState(false);
  const [messageFormatType, setMessageFormatType] = useState([
    {
      id: 1,
      label: "General",
      type: "link",
    },
    {
      id: 2,
      label: "Apply for Scholarship",
      type: "scholarship_apply",
    },
  ]);
  const [messageColorList, setMessageColorList] = useState([
    {
      id: 1,
      label: "General Message (Blue) (#EFF7FF)",
      value: "#EFF7FF",
    },
    {
      id: 2,
      label: "Warning Message (Red) (#FFF6F6)",
      value: "#FFF6F6",
    },
  ]);
  const [userTypesList, setUserTypesList] = useState([
    {
      id: 1,
      label: "Athlete/Player",
      value: "player",
    },
    {
      id: 2,
      label: "Parent",
      value: "parent",
    },
    {
      id: 3,
      label: "Team Manager",
      value: "teammanager",
    },
    {
      id: 4,
      label: "Team Coach",
      value: "teamcoach",
    },
    {
      id: 5,
      label: "Tournament Manager",
      value: "tournamentmanager",
    },
  ]);
  const [selectedUserType, setSelectedUserType] = useState([]);
  const [
    showUserTypeSelectionRequiredError,
    setShowUserTypeSelectionRequiredError,
  ] = useState(false);
  const currentDate = new Date().toISOString().split("T")[0];
  const ITEM_HEIGHT = 35;
  const ITEM_PADDING_TOP = 5;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 300,
      },
    },
  };
  const requiredStarStyle = { color: "red" };

  const formik = useFormik({
    initialValues: {
      messageName: selectedDataGridRow?.title ?? "",
      themeColor: selectedDataGridRow?.color ?? "",
      message: selectedDataGridRow?.desc ?? "",
      expireDate: dayjs(selectedDataGridRow?.expire_date) ?? "",
      linkURL: selectedDataGridRow?.link ?? "",
      messageFormatType: selectedDataGridRow?.format_type ?? "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (!selectedPlacement?.length) {
        setShowMessagePlacementSelectionRequiredError(true);
        return;
      }
      if (!selectedUserType?.length) {
        setShowUserTypeSelectionRequiredError(true);
        return;
      }

      const postData = {
        id: selectedDataGridRow?.id ? selectedDataGridRow?.id : undefined,
        title: values?.messageName ?? "",
        desc: values?.message ?? "",
        color: values?.themeColor ?? "",
        link: values?.linkURL ?? "",
        is_active: activeInactiveMessage,
        expire_date: values?.expireDate,
        type: selectedPlacement?.length
          ? selectedPlacement[0] === "Dashboard Middle Top"
            ? "DashboardMiddleTop"
            : ""
          : "",
        format_type: values?.messageFormatType ?? "",
        user_type: selectedUserType?.length ? selectedUserType?.join() : "",
      };
      addUpdateMessage(postData, activeInactiveMessage);
    },
  });

  useEffect(() => {
    if (selectedDataGridRow?.format_type) {
      formik.setFieldValue(
        "messageFormatType",
        selectedDataGridRow?.format_type,
      );
    }
  }, [selectedDataGridRow]);

  useEffect(() => {
    if (selectedDataGridRow) {
      let { title, color, desc, expire_date, link, is_active, user_type } =
        selectedDataGridRow;
      if (user_type && typeof user_type === "string") {
        setSelectedUserType(user_type?.split(","));
      }
      setSelectedPlacement([
        selectedDataGridRow?.type === "DashboardMiddleTop"
          ? "Dashboard Middle Top"
          : "",
      ]);
      formik.setFieldValue("messageName", title ?? "");
      formik.setFieldValue("themeColor", color ?? "");
      formik.setFieldValue("message", desc ?? "");
      formik.setFieldValue("expireDate", dayjs(expire_date) ?? "");
      formik.setFieldValue("linkURL", link ?? "");
      setActiveInactiveMessage(is_active ?? true);
    }
  }, [selectedDataGridRow]);

  useEffect(() => {
    if (selectedPlacement?.length) {
      setShowMessagePlacementSelectionRequiredError(false);
    }
  }, [selectedPlacement]);

  useEffect(() => {
    if (selectedUserType?.length) {
      setShowUserTypeSelectionRequiredError(false);
    }
  }, [selectedUserType]);

  const addUpdateMessage = async (bodyParams, activeInactiveFlag) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    bodyParams["is_active"] = activeInactiveFlag;

    let response = await addUpdateAdminMessageAction(
      dispatch,
      params,
      bodyParams,
    );

    try {
      if (response?.ok) {
        handleClose(false);
        setShowSuccessPopup(true);
      } else {
        dispatch({ type: SET_LOADER, data: false });
        dispatch({
          type: SET_SUCCESS_ERROR_POPUP,
          data: {
            open: true,
            type: "error",
            message:
              response?.message ?? "Something went wrong. Please try again!",
          },
        });
      }
    } catch (error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: error.message ?? "Something went wrong. Plese try again!",
        },
      });
    }
  };

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getAllMessages();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getAllMessages = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    let response = await getAllMessagesAction(params);
    if (response?.ok) {
      setMessagesList(response?.data?.data ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize;
    paginationOptions.limit = pageSize ?? 10;
    getAllMessages();
  };

  const handleClose = (isClosed) => {
    setOpen(false);
    // setSelectedPlacement([]);
    // setSelectedUserType([]);
    setActiveInactiveMessage(true);
    setConfirmPopupActiveInactiveMessage(false);
    if (isClosed) {
      setShowCancelSaveConfirmPopup(true);
      return;
    }
    getAllMessages();
  };

  const columns = [
    {
      field: "type",
      headerName: "MESSAGE PLACEMENT",
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (data) => (
        <Tooltip title={data?.row?.type ?? "N/A"}>
          <span>
            {data?.row?.type
              ? charValidate(
                  data?.row?.type === "DashboardMiddleTop"
                    ? "Dashboard Middle Top"
                    : "N/A",
                  25,
                )
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "title",
      headerName: "NAME",
      minWidth: 150,
      flex: 1,
      sortable: false,
      renderCell: (data) => (
        <Tooltip title={data?.row?.title ?? "N/A"}>
          <span>
            {data?.row?.title ? charValidate(data?.row?.title, 25) : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "desc",
      headerName: "MESSAGE IMPORTANCE",
      minWidth: 200,
      flex: 1,
      sortable: false,
      renderCell: (data) => (
        <Tooltip title={data?.row?.desc ?? "N/A"}>
          <span>
            {data?.row?.desc ? charValidate(data?.row?.desc, 25) : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "expire_date",
      headerName: "EXPIRES",
      minWidth: 120,
      flex: 1,
      sortable: false,
      align: "center",
      headerAlign: "center",
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.expire_date ? <h4>{data?.row?.expire_date}</h4> : "N/A"
          }
        >
          <span>
            {data?.row?.expire_date
              ? formatDate(data?.row?.expire_date)
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "is_active",
      headerName: "STATUS",
      align: "center",
      headerAlign: "center",
      renderCell: (data) => (
        <Box
          sx={{
            height: "38px",
            maxWidth: "120px",
            width: "100%",
            color: "white",
            fontSize: "12px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textTransform: "uppercase",
            background: data?.row?.is_active
              ? theme?.palette?.inputsLabels?.green
              : theme?.palette?.error?.main,
            borderRadius: "4px",
          }}
        >
          {data?.row?.is_active ? "ACTIVE" : "IN-ACTIVE"}
        </Box>
      ),
      flex: 1,
      minWidth: 110,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      minWidth: 100,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => {
        return (
          <Box>
            <SettingsIcon
              onClick={(e) => setPopoverAnchorEl(e?.currentTarget)}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={popoverAnchorEl}
              onClick={() => setPopoverAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                p: 1,
                "& .MuiPaper-root": {
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
                },
              }}
            >
              <ActionCard title={"Actions"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingY: 1.2, width: "200px" }}
                    onClick={handleEditClick}
                  >
                    Edit Details
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    color="success"
                    fullWidth
                    sx={{
                      width: "200px",
                      paddingY: 1.2,
                      mt: 2,
                      color: "white",
                      background: "green",
                    }}
                    onClick={() => {
                      setConfirmPopupActiveInactiveMessage(true);
                    }}
                  >
                    {selectedDataGridRow?.is_active
                      ? "Make Inactive"
                      : "Make Active"}
                  </Button>
                  <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25, mt: 3, mb: 1 }}
                  ></Box>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 2,
                      paddingY: 1.2,
                      width: "200px",
                      background: theme?.palette?.error?.dark,
                    }}
                    onClick={handleDeleteClick}
                  >
                    Delete Message
                  </Button>
                </Box>
              </ActionCard>
            </Popover>
          </Box>
        );
      },
    },
  ];

  const handleEditClick = () => {
    setPopoverAnchorEl(null);
    setOpen(true);
  };

  const handleDeleteClick = () => {
    setPopoverAnchorEl(null);
    setShowDeletePopup(true);
  };

  const handleConfirmDelete = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await deleteAdminMessageAction(dispatch, params, {
      id: selectedDataGridRow?.id,
    });
    if (response?.ok) {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: { open: true, type: "success", message: response.message },
      });
      setShowDeletePopup(false);
      setSelectedDataGridRow("");
      handleClose(false);
      getAllMessages();
    } else {
      dispatch({
        type: SET_SUCCESS_ERROR_POPUP,
        data: {
          open: true,
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  const handleMessagePlacementSelection = (e) => {
    let value = e?.target?.value;
    if (value) {
      setSelectedPlacement(value);
    }
  };

  const handleUserTypeSelection = (e) => {
    let value = e?.target?.value;
    if (value) {
      setSelectedUserType(value);
    }
  };

  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const gridToolbar = () => {
    return (
      <Box display={"flex"} alignItems="center" justifyContent="center">
        <Button
          variant="contained"
          size="small"
          onClick={() => {
            dispatch({ type: "RESET_CAMPAIGN_DATA" });
            navigate(Send_Email_Communicate);
          }}
          sx={{ paddingX: 2.5, paddingY: 1.2, m: 1 }}
        >
          Send Email
        </Button>
      </Box>
    );
  };

  return (
    <>
      <MuiDataGrid
        headerLabel={"Communicate"}
        gridData={messageList}
        columns={columns}
        paginationOptions={paginationOptions}
        handlePagination={handlePagination}
        tableRef={tableRef}
        isLoading={messageList ? false : true}
        onClickAddNew={() => {
          setOpen(true);
          formik.resetForm();
          setSelectedPlacement([]);
          setSelectedUserType([]);
        }}
        handleRowClick={(data) => setSelectedDataGridRow(data?.row)}
        gridToolbarButtons={() => gridToolbar()}
      />

      <Dialog
        open={open}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ p: 3 }}>
          {/* <Typography variant="h3" fontWeight={700}>
            Communicate
          </Typography> */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            p={0}
            sx={{
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "start",
              },
            }}
          >
            <Typography
              variant="h4"
              fontWeight={700}
              fontSize={20}
              color={theme?.palette?.text?.containerTextLabel}
              sx={{
                p: 1,
                [theme.breakpoints.down("md")]: {
                  p: 0,
                  mb: 1,
                },
              }}
            >
              Communicate
            </Typography>
            <Box position="relative" sx={{ cursor: "pointer" }}>
              <FormControlLabel
                control={
                  <CustomSwitch
                    checked={activeInactiveMessage}
                    onChange={(e) =>
                      setActiveInactiveMessage(e?.target?.checked)
                    }
                    size="sm"
                    sx={{ m: 1 }}
                  />
                }
                label={`Make active for this communication spot.`}
                labelPlacement="start"
                sx={{
                  "&.MuiFormControlLabel-root": {
                    ml: 0,
                  },
                  "& .MuiTypography-root": {
                    color: theme?.palette?.text?.containerTextLabel,
                    fontWeight: 400,
                    fontFamily: "inherit",
                  },
                }}
              />
            </Box>
          </Box>
        </DialogTitle>
        <Divider></Divider>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent
            sx={{
              p: 4,
              [theme.breakpoints.down("md")]: {
                p: 3,
              },
            }}
          >
            {step === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Grid container spacing={3}>
                    <Grid item md={6} xs={12}>
                      <CustomInputLabel
                        sx={{ fontWeight: "700", fontSize: 16, mb: 0 }}
                      >
                        <span style={requiredStarStyle}>*</span> Message
                        Placement:
                      </CustomInputLabel>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <Select
                        name="messagePlacementSelection"
                        multiple
                        displayEmpty
                        value={selectedPlacement}
                        onChange={handleMessagePlacementSelection}
                        input={<OutlinedInput sx={{ width: "100%" }} />}
                        inputProps={{
                          "aria-label": "Without label",
                        }}
                        renderValue={(selected) => {
                          if (
                            selected?.length &&
                            messagePlacementList?.length
                          ) {
                            return selected?.join(", ");
                          } else {
                            return (
                              <Typography
                                variant="p"
                                fontWeight={400}
                                fontSize={14}
                                sx={{ opacity: 0.5 }}
                              >
                                Select message placement...
                              </Typography>
                            );
                          }
                        }}
                        MenuProps={MenuProps}
                        className="MuiSelect-select"
                        sx={{
                          "& .MuiSelect-select": {
                            padding: "10.5px 14px",
                          },
                          width: 1,
                          maxWidth: "100%",
                        }}
                      >
                        <MenuItem disabled value="">
                          Select message placement...
                        </MenuItem>
                        {messagePlacementList?.map((placementType) => (
                          <MenuItem
                            // disabled={state?.user?.user?.id == msg?.id}
                            key={placementType}
                            value={placementType}
                          >
                            <Checkbox
                              checked={
                                selectedPlacement.indexOf(placementType) > -1
                              }
                              // disabled={state?.user?.user?.id == msg?.id}
                            />
                            <ListItemText primary={placementType} />
                          </MenuItem>
                        ))}
                      </Select>
                      {showMessagePlacementSelectionRequiredError && (
                        <FormHelperText error>
                          Message placement selection is required
                        </FormHelperText>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomInputLabel htmlFor="messageName" sx={inputLabelStyle}>
                    Name of Message <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.messageName && formik.errors.messageName,
                    )}
                  >
                    <CustomTextField
                      id="messageName"
                      name="messageName"
                      placeholder="Enter a name..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.messageName}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(
                      formik.touched.messageName && formik.errors.messageName,
                    ) && (
                      <FormHelperText error>
                        {formik.errors.messageName}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomInputLabel htmlFor="themeColor" sx={inputLabelStyle}>
                    CHOOSE BACKGROUND THEME COLOR{" "}
                    <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <Select
                    name="themeColor"
                    labelId="themeColor"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.themeColor}
                    input={
                      <OutlinedInput
                        label=""
                        placeholder="Select message type"
                        sx={{ width: "100%", height: 43 }}
                      />
                    }
                    MenuProps={MenuProps}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    renderValue={(selected) => {
                      if (selected) {
                        let selectedColor = messageColorList?.find(
                          (color) => color?.value === selected,
                        );
                        return selectedColor?.label;
                      } else {
                        return (
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            sx={{ opacity: 0.5 }}
                          >
                            Select background color for the message...
                          </Typography>
                        );
                      }
                    }}
                    displayEmpty
                  >
                    {messageColorList && messageColorList?.length
                      ? messageColorList?.map((color) => (
                          <MenuItem key={color?.id} value={color?.value}>
                            {color?.label}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {Boolean(
                    formik.touched.themeColor && formik.errors.themeColor,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.themeColor}
                    </FormHelperText>
                  )}
                </Grid>
                {/* <Grid item xs={6}>
                  <CustomInputLabel htmlFor="themeColor">
                    CHOOSE COLOR THEME <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.themeColor &&
                      formik.errors.themeColor,
                    )}
                    sx={{
                      width: "100%",
                      "& .MuiInputBase-formControl .MuiInputBase-formControl": {
                        height: "37px",
                        padding: "10px 10px",
                      },
                      "& .MuiIconButton-root": {
                        background: "transparent",
                        borderRadius: "0 5px 5px 0",
                        left: 16,
                      },
                    }}
                  >
                    <InputLabel
                      htmlFor="theme-color"
                      shrink={false}
                      sx={{
                        fontFamily: "'Poppins', sans-serif, Arial",
                        fontWeight: 400,
                        fontSize: 14,
                      }}
                    >
                      {formik?.values?.themeColor
                        ? formik?.values?.themeColor
                        : "Choose message color theme..."}
                    </InputLabel>
                    <OutlinedInput
                      name="themeColor"
                      type={"color"}
                      value={formik.values.themeColor}
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      sx={{
                        "& ::file-selector-button": {
                          opacity: 0,
                          display: "none",
                        },
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: 350,
                          boxShadow: "none",
                          opacity: 0,
                        },
                        "& .MuiInputBase-input": {
                          padding: "13.5px 10px !important",
                        },
                      }}
                      inputProps={{
                        style: {
                          opacity: 0,
                        },
                      }}
                    />
                    {Boolean(
                      formik.touched.themeColor &&
                      formik.errors.themeColor,
                    ) && (
                        <FormHelperText error>
                          {formik.errors.themeColor}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid> */}

                <Grid item md={12} xs={12}>
                  <CustomInputLabel htmlFor="message" sx={inputLabelStyle}>
                    Enter Message <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.message && formik.errors.message,
                    )}
                  >
                    <CustomTextField
                      multiline
                      id="message"
                      name="message"
                      placeholder="Enter message... (limit 250 characters)"
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.message}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          boxShadow: "none",
                        },
                      }}
                      rows={6}
                      cols={6}
                    />
                    {Boolean(
                      formik.touched.message && formik.errors.message,
                    ) && (
                      <FormHelperText error>
                        {formik.errors.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomInputLabel htmlFor="expireDate" sx={inputLabelStyle}>
                    Message Expire Date <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      disablePast
                      name="expireDate"
                      onChange={(newVal) =>
                        formik.setFieldValue("expireDate", newVal)
                      }
                      value={formik.values.expireDate}
                      views={["year", "month", "day"]}
                      sx={{
                        "&.MuiFormControl-root": {
                          width: "100%",
                        },
                        "& .MuiOutlinedInput-input": {
                          border: `0`,
                          boxShadow: "none",
                          width: "100%",
                          padding: "12px 14px",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  {Boolean(
                    formik.touched.expireDate && formik.errors.expireDate,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.expireDate}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomInputLabel htmlFor="linkURL" sx={inputLabelStyle}>
                    Link Message To:
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.linkURL && formik.errors.linkURL,
                    )}
                  >
                    <CustomTextField
                      id="linkURL"
                      name="linkURL"
                      placeholder="Enter URL to link message to..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.linkURL}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(
                      formik.touched.linkURL && formik.errors.linkURL,
                    ) && (
                      <FormHelperText error>
                        {formik.errors.linkURL}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomInputLabel
                    htmlFor="messageFormatType"
                    sx={inputLabelStyle}
                  >
                    Message For: <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <Select
                    name="messageFormatType"
                    labelId="messageFormatType"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    value={formik.values.messageFormatType}
                    input={
                      <OutlinedInput
                        label=""
                        placeholder="Select message type"
                        sx={{ width: "100%", height: 43 }}
                      />
                    }
                    MenuProps={MenuProps}
                    inputProps={{
                      "aria-label": "Without label",
                    }}
                    renderValue={(selected) => {
                      if (selected) {
                        let selectedMsg = messageFormatType?.find(
                          (msg) => msg?.type === selected,
                        );
                        return selectedMsg?.label;
                      } else {
                        return (
                          <Typography
                            variant="p"
                            fontWeight={400}
                            fontSize={14}
                            sx={{ opacity: 0.5 }}
                          >
                            Select message type...
                          </Typography>
                        );
                      }
                    }}
                    displayEmpty
                  >
                    {messageFormatType && messageFormatType?.length
                      ? messageFormatType?.map((msg) => (
                          <MenuItem key={msg?.id} value={msg?.type}>
                            {msg?.label}
                          </MenuItem>
                        ))
                      : null}
                  </Select>
                  {Boolean(
                    formik.touched.messageFormatType &&
                      formik.errors.messageFormatType,
                  ) && (
                    <FormHelperText error>
                      {formik.errors.messageFormatType}
                    </FormHelperText>
                  )}
                </Grid>

                <Grid item md={6} xs={12}>
                  <CustomInputLabel htmlFor="userType" sx={inputLabelStyle}>
                    User Type: <span style={requiredStarStyle}>*</span>
                  </CustomInputLabel>
                  <Box display="flex" flexDirection="column" width={1}>
                    <Select
                      name="userType"
                      multiple
                      displayEmpty
                      value={selectedUserType}
                      onChange={handleUserTypeSelection}
                      input={
                        <OutlinedInput sx={{ width: "100%", height: 46 }} />
                      }
                      inputProps={{
                        "aria-label": "Without label",
                      }}
                      renderValue={(selected) => {
                        if (selected?.length && userTypesList?.length) {
                          let roles = [];
                          userTypesList?.filter((userType) => {
                            if (selected.includes(userType?.value)) {
                              roles.push(userType?.label);
                            }
                          });
                          if (roles?.length) {
                            return roles.join(", ");
                          }
                          return selected?.join(", ");
                        } else {
                          return (
                            <Typography
                              variant="p"
                              fontWeight={400}
                              fontSize={14}
                              sx={{ opacity: 0.5 }}
                            >
                              Select user role to show message...
                            </Typography>
                          );
                        }
                      }}
                      MenuProps={MenuProps}
                      className="MuiSelect-select"
                      sx={{
                        "& .MuiSelect-select": {
                          padding: "12px 14px",
                        },
                        width: 1,
                      }}
                    >
                      <MenuItem disabled value="">
                        Select user role to show message...
                      </MenuItem>
                      {userTypesList?.map((userType) => (
                        <MenuItem key={userType?.id} value={userType?.value}>
                          <Checkbox
                            checked={
                              selectedUserType.indexOf(userType?.value) > -1
                            }
                          />
                          <ListItemText primary={`${userType?.label}`} />
                        </MenuItem>
                      ))}
                    </Select>
                    {showUserTypeSelectionRequiredError && (
                      <FormHelperText error>
                        User type selection is required
                      </FormHelperText>
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}
          </DialogContent>
          <DialogActions
            sx={{
              p: 4,
              pt: 0,
              [theme.breakpoints.down("md")]: {
                p: 3,
                pt: 0,
              },
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Button
              size="small"
              variant="contained"
              sx={{ width: "100px", height: "43px" }}
              color="inherit"
              onClick={() => handleClose(true)}
            >
              Cancel
            </Button>
            <Button
              size="small"
              variant="contained"
              type="submit"
              sx={{ width: "100px", height: "43px" }}
              onClick={() => {
                if (!selectedPlacement?.length) {
                  setShowMessagePlacementSelectionRequiredError(true);
                }
                if (!selectedUserType?.length) {
                  setShowUserTypeSelectionRequiredError(true);
                }
              }}
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      {showDeletePopup && (
        <DeleteConfirmPopup
          title={"Communicate"}
          message={"Are you sure, want to delete this message?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setSelectedDataGridRow("");
          }}
          handleConfirm={handleConfirmDelete}
        />
      )}
      {showCancelSaveConfirmPopup && (
        <CancelConfirmDialog
          title={"Communicate"}
          message={"Are you sure you want to close without saving?"}
          open={showCancelSaveConfirmPopup}
          handleClose={() => setShowCancelSaveConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelSaveConfirmPopup(false);
            setOpen(true);
          }}
          handleConfirmClose={() => {
            setShowCancelSaveConfirmPopup(false);
            setSelectedDataGridRow(undefined);
            formik.resetForm();
            setCheckCommunity(false);
            setCheckPost(false);
            setShowMessagePlacementSelectionRequiredError(false);
            setShowUserTypeSelectionRequiredError(false);
          }}
        />
      )}
      {showSuccessPopup && (
        <SuccessPopup
          open={showSuccessPopup}
          handleClose={() => setShowSuccessPopup(false)}
          secondaryTitle="Message Saved/Updated"
          isShowCloseBtn={false}
          isShowSubTitle={true}
          secondaryMessage="Tap anywhere to continue"
        />
      )}

      {confirmPopupActiveInactiveMessage && (
        <CancelConfirmDialog
          title={"Communicate"}
          message={`Are you sure, want to make this message ${
            selectedDataGridRow?.is_active ? "Inactive" : "Active"
          }?`}
          open={confirmPopupActiveInactiveMessage}
          handleClose={() => {
            setConfirmPopupActiveInactiveMessage(false);
            setSelectedDataGridRow("");
          }}
          handleCancel={() => {
            setConfirmPopupActiveInactiveMessage(false);
            setSelectedDataGridRow("");
          }}
          handleConfirmClose={() =>
            addUpdateMessage(
              selectedDataGridRow,
              !selectedDataGridRow?.is_active,
            )
          }
          confirmBtnLabel="Yes"
        />
      )}
    </>
  );
};

export default AdminMessage;

import {
    Box,
    Button,
    Checkbox,
    FormHelperText,
    Grid,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    TextField,
    Typography,
    useTheme, FormControl, InputLabel, InputAdornment, IconButton
} from '@mui/material';
import { CustomInputLabel } from '../../components/Common/Inputs/InputLabel';
import { useEffect, useState } from 'react';
import AddTournamentManager from './AddTournamentManager';
import AddDivision from './AddDivision';
import AddGrade from './AddGrade';
import { getDivisions, SET_TOAST, getGrades, getTournamentManagers, uploadDocumentAction, SET_LOADER, getProductsByType, getDocuments, getAllSubOrg } from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { UploadFileRounded } from '@mui/icons-material';
import { getFileNameFromFirebaseUrl } from '../../utils/functions';
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { SET_SUCCESS_ERROR_POPUP } from '../../store/actions';


const AdminTournamentForm = ({ formik, isEdit, selectedDataGridRow }) => {

    const ITEM_HEIGHT = 35;
    const ITEM_PADDING_TOP = 5;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 300,
            },
        },
    };
    const dispatch = useDispatch();

    const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
    const selectionInputStyle = { width: "100%", height: 40, width: 300, };
    const theme = useTheme();
    const state = useSelector((state) => state);
    const [showAddTeamOwnerModal, setShowAddTeamOwnerModal] = useState(false);
    const [showAddDivisionModal, setShowAddDivisionModal] = useState(false);
    const [showAddGradeModal, setShowAddGradeModal] = useState(false);

    const [selectedOwners, setSelectedOwners] = useState([]);
    const [managerList, setManagerList] = useState([]);
    const [singleDivisionList, setSingleDivisionList] = useState([]);
    const [divisionList, setDivisionList] = useState([]);
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [gradeList, setGradeList] = useState([]);
    const [selectedGrades, setSelectedGrades] = useState([]);
    const [documentList, setDocumentList] = useState([]);
    const [selectedDocuments, setSelectedDocuments] = useState([]);
    const [selectedSingleDivision, setSelectedSingleDivision] = useState("");
    const [teamFee, setTeamFee] = useState("");
    const [athleteFee, setAthleteFee] = useState("");
    const [divisionGrade, setDivisionGrade] = useState({})
    const [documentTypes, setDocumentTypes] = useState([
        {
            id: 1,
            docType: "schedule", type: "Add Schedule",
            docUrl: "",
            placeholder: "Upload schedule..."
        },
        {
            id: 2,
            docType: "rules", type: "Add Rules",
            docUrl: "",
            placeholder: "Upload Rules..."
        },
        {
            id: 3,
            docType: "legal",
            type: "Add Legal/Waivers",
            docUrl: "",
            placeholder: "Upload legal/waivers..."
        },
    ]);
    const [productTeamList, setProductTeamList] = useState([]);
    const currentDate = new Date().toISOString().split('T')[0];
    const [subOrgList, setSubOrgList] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [offset, setOffset] = useState(0);

    const [hasSubOrgMore, setHasSubOrgMore] = useState(true);
    const [subOrgOffset, setSubOrgOffset] = useState(0);

    useEffect(() => {
        getDivisionList();
        getGradeList();
        getTournamentManagerList()
        getProductTeamList()
        getDocumentsList()
        getSubOrgList()
    }, [])

    useEffect(() => {
        if (isEdit && selectedDataGridRow) {
            formik.setFieldValue("name", selectedDataGridRow?.name || "")
            formik.setFieldValue("start_date", dayjs(selectedDataGridRow?.start_date) || "")
            formik.setFieldValue("end_date", dayjs(selectedDataGridRow?.end_date) || "")
            formik.setFieldValue("total_team", selectedDataGridRow?.total_team || 0)
            formik.setFieldValue("description", selectedDataGridRow?.description || "")
            formik.setFieldValue("address", selectedDataGridRow?.street?.address || "")
            formik.setFieldValue("city", selectedDataGridRow?.street?.city || "")
            formik.setFieldValue("state", selectedDataGridRow?.street?.state || "")
            formik.setFieldValue("zip_code", selectedDataGridRow?.street?.zip_code || "")
            formik.setFieldValue("primary_manager", selectedDataGridRow?.primary_manager?.id || "")
            formik.setFieldValue("document", selectedDataGridRow?.document || "")
            formik.setFieldValue("sub_organization", selectedDataGridRow?.sub_organization?.id || "")
            formik.setFieldValue("schedule", selectedDataGridRow?.schedule || "")
            formik.setFieldValue("rules", selectedDataGridRow?.rules || "")
            formik.setFieldValue("legal", selectedDataGridRow?.legal || "")
            documentTypes.map(obj => {
                if (obj.docType == 'schedule') {
                    obj.placeholder = selectedDataGridRow?.schedule ? getFileNameFromFirebaseUrl(selectedDataGridRow?.schedule) : obj.placeholder;
                } else if (obj.docType == 'rules') {
                    obj.placeholder = selectedDataGridRow?.rules ? getFileNameFromFirebaseUrl(selectedDataGridRow?.rules) : obj.placeholder;
                } else if (obj.docType == 'legal') {
                    obj.placeholder = selectedDataGridRow?.legal ? getFileNameFromFirebaseUrl(selectedDataGridRow?.legal) : obj.placeholder;
                }
                return obj;
            });

            const ownerIds = selectedDataGridRow?.owner?.map(x => x.id)
            setSelectedOwners(ownerIds)
            formik.setFieldValue("owner", ownerIds || [])

            const divisionIds = selectedDataGridRow.division?.map(x => x.id)
            setSelectedDivisions(divisionIds)
            formik.setFieldValue("division", divisionIds || [])

            const documentIds = selectedDataGridRow?.document?.split(',')
            setSelectedDocuments(documentIds)

            setTeamFee(selectedDataGridRow.entry_fee?.cost ?? 0);
            setAthleteFee(selectedDataGridRow.entry_fee?.athlete_fee ?? 0);
            formik.setFieldValue("entry_fee", selectedDataGridRow?.entry_fee?.id || "")

            setSingleDivisionList(selectedDataGridRow?.division_grade)
            const updatedDivisionGrade = {};
            selectedDataGridRow?.division_grade?.forEach(obj => {
                const gradeIds = obj.grades.map(x => x.id);
                updatedDivisionGrade[obj.id] = gradeIds;
            });
            setDivisionGrade(updatedDivisionGrade);
            formik.setFieldValue("division_grade", updatedDivisionGrade);
        }
    }, [isEdit])

    const getDivisionList = async () => {
        let response = await getDivisions(dispatch);
        if (response?.ok) {
            setDivisionList(response?.data ?? []);
        } else {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: response.message ?? "Something went wrong for division data",
                },
            });
        }
    }

    const getGradeList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
        }
        let response = await getGrades(params, dispatch);
        if (response?.ok) {
            setGradeList(response?.data ?? []);
        } else {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: response.message ?? "Something went wrong for grade data",
                },
            });
        }
    }

    const getSubOrgList = async () => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
            skip: subOrgOffset,
            limit: 20
        };
        let response = await getAllSubOrg(params);
        if (response?.ok) {
            const res = response?.data?.data ?? []
            setSubOrgList(prevOptions => [...prevOptions, ...res]);
            setSubOrgOffset(prevOffset => prevOffset + res.length);
            if (res.length === 0) {
                setHasSubOrgMore(false);
            }
        } else {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: response.message ?? "Something went wrong for sub-organization data",
                },
            });
        }
    }

    const handleSubOrgScroll = (e) => {
        const target = e.target;
        if ((target?.scrollHeight - target?.scrollTop) === target?.clientHeight && hasSubOrgMore) {
            getSubOrgList();
        }
    };

    const getTournamentManagerList = async () => {
        let response = await getTournamentManagers(dispatch);
        if (response?.ok) {
            setManagerList(response?.data ?? []);
        } else {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: response.message ?? "Something went wrong for manager data",
                },
            });
        }
    }

    const getProductTeamList = async () => {
        let params = {
            skip: offset,
            limit: 20,
            type: 'team',
        };
        let response = await getProductsByType(dispatch, params);
        if (response?.ok) {
            const res = response?.data?.data ?? []
            setProductTeamList(prevOptions => [...prevOptions, ...res]);
            setOffset(prevOffset => prevOffset + res.length);
            if (res.length === 0) {
                setHasMore(false);
            }
        } else {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: response.message ?? "Something went wrong for product team data",
                },
            });
        }
    }

    const handleScroll = (e) => {
        const target = e.target;
        if ((target?.scrollHeight - target?.scrollTop) === target?.clientHeight && hasMore) {
            getProductTeamList();
        }
    };

    const getDocumentsList = async () => {
        let response = await getDocuments(dispatch);
        if (response?.ok) {
            setDocumentList(response?.data ?? []);
        } else {
            dispatch({
                type: SET_TOAST,
                data: {
                    type: "error",
                    message: response.message ?? "Something went wrong for documents data",
                },
            });
        }
    }

    const handleChangeOwner = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedOwners(value);
        formik.setFieldValue("owner", value);
    };

    const handleChangeDivision = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedDivisions(value);
        const filterArr = divisionList.filter(item => value.includes(item.id));
        for (const key in divisionGrade) {
            if (!value.includes(Number(key))) {
                delete divisionGrade[key]; //remove key from Object if key does not exist in value array
            }
        }

        filterArr.map(obj => {
            if (!divisionGrade?.hasOwnProperty(obj?.id)) {
                const updatedDivisionGrade = {
                    ...divisionGrade,
                    [obj?.id]: [], // Update the specific division with the new value
                };
                setDivisionGrade(updatedDivisionGrade);
                formik.setFieldValue("division_grade", updatedDivisionGrade);
            }
        });
        setSingleDivisionList(filterArr)
        formik.setFieldValue("division", value);
    };

    const handleChangeGrade = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedGrades(value);
        if (selectedSingleDivision) {
            const updatedDivisionGrade = {
                ...divisionGrade, // Copy the existing state
                [selectedSingleDivision]: value, // Update the specific division with the new value
            };
            setDivisionGrade(updatedDivisionGrade);
            formik.setFieldValue("division_grade", updatedDivisionGrade);
        }
    };

    const handleChangeDocument = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedDocuments(value);
        formik.setFieldValue("document", value?.join(','));
    };

    const handleChangeProductTeam = (event) => {
        const {
            target: { value },
        } = event;
        formik.setFieldValue("entry_fee", value)
        const selected = productTeamList?.find((tm) => tm?.id === value);
        setTeamFee(selected?.cost ?? 0);
        setAthleteFee(selected?.athlete_fee ?? 0);
    };

    const handleChangeSingleDivision = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedSingleDivision(value);
        if (Object.keys(divisionGrade).length !== 0) {
            if (divisionGrade[value]) {
                const arrayForId = divisionGrade[value];
                setSelectedGrades(arrayForId)
            } else {
                setSelectedGrades([])
            }
        } else {
            setSelectedGrades([])
        }
    };

    const handleTeamOwnerModalClose = (isClosed) => {
        setShowAddTeamOwnerModal(false);
        if (!isClosed) {
            getTournamentManagerList()
        }
    };

    const handleDivisionModalClose = (isClosed) => {
        setShowAddDivisionModal(false);
        if (!isClosed) {
            getDivisionList()
        }
    };

    const handleGradeModalClose = (isClosed) => {
        setShowAddGradeModal(false);
        if (!isClosed) {
            getGradeList()
        }
    };

    const handleChangeFileUpload = (e, id) => {
        let file = e?.target?.files[0];
        let newArr = documentTypes.map(obj => {
            if (obj.id == id) {
                obj.placeholder = file?.name
            }
            return obj;
        });
        setDocumentTypes(newArr);
        uploadDocument(file, id);
    }

    const uploadDocument = async (file, id) => {
        let params = {
            userToken: state?.user?.token,
            orgToken: state?.org?.org?.token,
        }
        let formData = new FormData();
        formData.append("files", file ?? "");
        formData.append("type", "FilmRoom");
        formData.append("fileType", "Thumbnail");
        formData.append("userId", state?.user?.user?.id ?? "");
        try {
            dispatch({ type: SET_LOADER, data: true });
            let response = await uploadDocumentAction(dispatch, params, formData);
            if (response?.ok) {
                let url = response?.url ?? "";
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'success', message: response.message ?? "Sucess!" }
                })
                let newArr = documentTypes.map(obj => {
                    if (obj.id == id) {
                        obj.docUrl = url;
                    }
                    return obj;
                });
                if (id === 1) {
                    formik.setFieldValue("schedule", url)
                } else if (id === 2) {
                    formik.setFieldValue("rules", url)
                } else if (id === 3) {
                    formik.setFieldValue("legal", url)
                }
                setDocumentTypes(newArr);
            } else {
                dispatch({
                    type: SET_SUCCESS_ERROR_POPUP,
                    data: { open: true, type: 'error', message: response.message ?? "Something went wrong" }
                })
            }
        } catch (error) {
            dispatch({ type: SET_LOADER, data: false });
            dispatch({
                type: SET_SUCCESS_ERROR_POPUP,
                data: { open: true, type: 'error', message: error.message ?? "Something went wrong " }
            })
        }
    }

    return (
        <>
            <Grid container spacing={2} sx={{
                mt: 4, mb: 4, px: 3,
                [theme.breakpoints.down("sm")]: {
                    mt: 1,
                    mb: 3,
                }
            }}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Box sx={{
                        display: "flex",
                        height: "100%",
                        alignItems: "self-start",
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    CHOOSE SUB-ORGANIZATION
                                </CustomInputLabel>
                                <Select
                                    name="sub_organization"
                                    //onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.sub_organization}
                                    input={
                                        <OutlinedInput
                                            sx={selectionInputStyle}
                                        />
                                    }
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                width: 300,
                                            },
                                            onScroll: (event) => {
                                                handleSubOrgScroll(event)
                                            }
                                        },
                                    }}
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    className="MuiSelect-select"
                                    sx={{
                                        "& .MuiSelect-select": {
                                            padding: "10.5px 14px"
                                        },
                                        width: "100%",
                                        //maxWidth: "300px"
                                    }}
                                    displayEmpty
                                    renderValue={(value) => {
                                        if (value) {
                                            let selected = subOrgList?.find((suborg) => suborg?.id === value);
                                            if (selected) {
                                                return selected?.title
                                            }
                                        } else {
                                            return (
                                                <Typography
                                                    variant="p"
                                                    fontWeight={400}
                                                    fontSize={14}
                                                    sx={{ opacity: 0.5 }}
                                                >
                                                    Select from Sub Organizations...
                                                </Typography>
                                            );
                                        }
                                    }}
                                    disabled={isEdit}
                                >
                                    {subOrgList?.map((suborg) => (
                                        <MenuItem key={suborg?.id} value={suborg?.id}>
                                            {suborg?.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {Boolean(formik.touched.sub_organization && formik.errors.sub_organization) && (
                                    <FormHelperText error>{formik.errors.sub_organization}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    NAME
                                </CustomInputLabel>
                                <TextField
                                    name="name"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.name}
                                    sx={{
                                        width: "100%",
                                        ".MuiInputBase-input": {
                                            padding: "10.5px 14px !important",
                                            outline: "none",
                                        },
                                    }}
                                    // className="input-style"
                                    placeholder="Enter tournament name..."
                                    error={Boolean(formik.touched.name && formik.errors.name)}
                                />
                                {Boolean(formik.touched.name && formik.errors.name) && (
                                    <FormHelperText error>{formik.errors.name}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    fontWeight={700}
                                    fontSize={20}
                                    color={theme?.palette?.text?.containerTextLabel}
                                >
                                    Date Of Tournament
                                </Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    Start Date
                                </CustomInputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        disablePast
                                        name="start_date"
                                        onChange={(newVal) => formik.setFieldValue("start_date", newVal)}
                                        value={dayjs(formik.values.start_date)}
                                        views={['year', 'month', 'day']}
                                        sx={{
                                            "&.MuiFormControl-root": {
                                                width: "100%",
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                {Boolean(formik.touched.start_date && formik.errors.start_date) && (
                                    <FormHelperText error>{formik.errors.start_date}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    End Date
                                </CustomInputLabel>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DatePicker
                                        name="end_date"
                                        onChange={(newVal) => formik.setFieldValue("end_date", newVal)}
                                        value={dayjs(formik.values.end_date)}
                                        views={['year', 'month', 'day']}
                                        minDate={formik.values.start_date}
                                        sx={{
                                            "&.MuiFormControl-root": {
                                                width: "100%",
                                            },
                                        }}
                                    />
                                </LocalizationProvider>
                                {Boolean(formik.touched.end_date && formik.errors.end_date) && (
                                    <FormHelperText error>{formik.errors.end_date}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    TOTAL TEAMS (Enter 0 for unlimited)
                                </CustomInputLabel>
                                <TextField
                                    name="total_team"
                                    type='number'
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.total_team}
                                    sx={{
                                        width: "100%",
                                        ".MuiInputBase-input": {
                                            padding: "10.5px 14px !important",
                                            outline: "none",
                                        },
                                    }}
                                    // className="input-style"
                                    placeholder="Enter Total Teams..."
                                    error={Boolean(formik.touched.total_team && formik.errors.total_team)}
                                />
                                {Boolean(formik.touched.total_team && formik.errors.total_team) && (
                                    <FormHelperText error>{formik.errors.total_team}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    DESCRIPTION
                                </CustomInputLabel>
                                <TextField
                                    name="description"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    sx={{
                                        width: "100%",
                                        ".MuiInputBase-input": {
                                            padding: "10.5px 14px !important",
                                            outline: "none",
                                        },
                                    }}
                                    // className="input-style"
                                    placeholder="Enter description..."
                                    error={Boolean(formik.touched.description && formik.errors.description)}
                                    multiline
                                    rows={3}
                                />
                                {Boolean(formik.touched.description && formik.errors.description) && (
                                    <FormHelperText error>{formik.errors.description}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <Box
                            sx={{
                                width: "1px",
                                background: "gray",
                                opacity: 0.3,
                                ml: 3,
                                mr: 1,
                                height: "100%",
                                [theme.breakpoints.down("sm")]: {
                                    display: "none",
                                }
                            }}></Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Box sx={{
                        display: "flex",
                        height: "100%",
                        alignItems: "self-start",
                    }}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    STREET ADDRESS
                                </CustomInputLabel>
                                <TextField
                                    name="address"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.address}
                                    sx={{
                                        width: "100%",
                                        ".MuiInputBase-input": {
                                            padding: "10.5px 14px !important",
                                            outline: "none",
                                        },
                                    }}
                                    // className="input-style"
                                    placeholder="Enter address..."
                                    error={Boolean(formik.touched.address && formik.errors.address)}
                                />
                                {Boolean(formik.touched.address && formik.errors.address) && (
                                    <FormHelperText error>{formik.errors.address}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    CITY
                                </CustomInputLabel>
                                <TextField
                                    name="city"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.city}
                                    sx={{
                                        width: "100%",
                                        ".MuiInputBase-input": {
                                            padding: "10.5px 14px !important",
                                            outline: "none",
                                        },
                                    }}
                                    // className="input-style"
                                    placeholder="Enter City..."
                                    error={Boolean(formik.touched.city && formik.errors.city)}
                                />
                                {Boolean(formik.touched.city && formik.errors.city) && (
                                    <FormHelperText error>{formik.errors.city}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    STATE
                                </CustomInputLabel>
                                <TextField
                                    name="state"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.state}
                                    sx={{
                                        width: "100%",
                                        ".MuiInputBase-input": {
                                            padding: "10.5px 14px !important",
                                            outline: "none",
                                        },
                                    }}
                                    // className="input-style"
                                    placeholder="Enter State..."
                                    error={Boolean(formik.touched.state && formik.errors.state)}
                                />
                                {Boolean(formik.touched.state && formik.errors.state) && (
                                    <FormHelperText error>{formik.errors.state}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    Zip
                                </CustomInputLabel>
                                <TextField
                                    name="zip_code"
                                    variant="outlined"
                                    onBlur={formik.handleBlur}
                                    onChange={formik.handleChange}
                                    value={formik.values.zip_code}
                                    sx={{
                                        width: "100%",
                                        ".MuiInputBase-input": {
                                            padding: "10.5px 14px !important",
                                            outline: "none",
                                        },
                                    }}
                                    // className="input-style"
                                    placeholder="Enter Zip Code"
                                    type="number"
                                    error={Boolean(formik.touched.zip_code && formik.errors.zip_code)}
                                />
                                {Boolean(formik.touched.zip_code && formik.errors.zip_code) && (
                                    <FormHelperText error>{formik.errors.zip_code}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <CustomInputLabel sx={inputLabelStyle}>
                                    TEAM ENTRY FEE
                                </CustomInputLabel>
                                <Select
                                    name="entry_fee"
                                    onChange={handleChangeProductTeam}
                                    value={formik.values.entry_fee}
                                    input={
                                        <OutlinedInput
                                            sx={selectionInputStyle}
                                        />
                                    }
                                    MenuProps={{
                                        PaperProps: {
                                            style: {
                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                width: 300,
                                            },
                                            onScroll: (event) => {
                                                handleScroll(event)
                                            }
                                        },
                                    }}
                                    inputProps={{
                                        "aria-label": "Without label",
                                    }}
                                    className="MuiSelect-select"
                                    sx={{
                                        "& .MuiSelect-select": {
                                            padding: "10.5px 14px"
                                        },
                                        width: "100%",
                                        //maxWidth: "300px"
                                    }}
                                    displayEmpty
                                    renderValue={(value) => {
                                        if (value) {
                                            let selected = productTeamList?.find((tm) => tm?.id === value);
                                            if (selected) {
                                                return selected?.name
                                            }
                                        } else {
                                            return (
                                                <Typography
                                                    variant="p"
                                                    fontWeight={400}
                                                    fontSize={14}
                                                    sx={{ opacity: 0.5 }}
                                                >
                                                    Select Entry Fee...
                                                </Typography>
                                            );
                                        }
                                    }}
                                    disabled={isEdit}
                                >
                                    {productTeamList?.map((productTeam) => (
                                        <MenuItem key={productTeam?.id} value={productTeam?.id}>
                                            {productTeam?.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {Boolean(formik.touched.entry_fee && formik.errors.entry_fee) && (
                                    <FormHelperText error>{formik.errors.entry_fee}</FormHelperText>
                                )}
                            </Grid>
                            {
                                formik?.values?.entry_fee &&
                                <>
                                    <Grid item xs={6}>
                                        <CustomInputLabel sx={inputLabelStyle}>
                                            TEAM FEE : ${teamFee?.toFixed(2)}
                                        </CustomInputLabel>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <CustomInputLabel sx={inputLabelStyle}>
                                            PLAYER FEE : ${athleteFee?.toFixed(2)}
                                        </CustomInputLabel>
                                    </Grid>
                                </>
                            }
                        </Grid>
                        <Box
                            sx={{
                                width: "1px",
                                background: "gray",
                                opacity: 0.3,
                                ml: 3,
                                mr: 1,
                                height: "100%",
                                [theme.breakpoints.down("lg")]: {
                                    display: "none",
                                }
                            }}></Box>
                    </Box>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                fontSize={20}
                                color={theme?.palette?.text?.containerTextLabel}
                                sx={{
                                    mb: -1.5,
                                }}
                            >
                                Tournament Managers
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                labelId="owner-lblid"
                                multiple
                                displayEmpty
                                value={selectedOwners}
                                onChange={handleChangeOwner}
                                input={
                                    <OutlinedInput
                                        label=""
                                        placeholder="Select Managers"
                                        sx={{ width: '100%', height: 45 }}
                                    />
                                }
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                renderValue={(selected) => {
                                    if (selected?.length && managerList?.length) {
                                        let fullName = [];
                                        managerList?.filter((owner) => {
                                            if (selected.includes(owner?.id)) {
                                                const ownername = owner?.first_name + " " + owner?.last_name
                                                fullName.push(ownername);
                                            }
                                        });
                                        if (fullName?.length) {
                                            return fullName.join(", ");
                                        }
                                        return selected?.join(", ");
                                    } else {
                                        return (
                                            <Typography
                                                variant="p"
                                                fontWeight={400}
                                                fontSize={14}
                                                sx={{ opacity: 0.5 }}
                                            >
                                                Select Managers...
                                            </Typography>
                                        );
                                    }
                                }}
                                MenuProps={MenuProps}
                            >
                                {managerList?.map((owner) => (
                                    <MenuItem key={owner?.id} value={owner?.id}>
                                        <Checkbox
                                            checked={selectedOwners.indexOf(owner?.id) > -1}
                                        />
                                        <ListItemText primary={owner?.first_name + " " + owner?.last_name} />
                                        <StarRoundedIcon sx={{ color: '#f1cb00', mr: 1 }} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {Boolean(formik.touched.owner && formik.errors.owner) && (
                                <FormHelperText error>{formik.errors.owner}</FormHelperText>
                            )}
                        </Grid>
                        {/* <Grid item xs={10}>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ paddingX: 3.5, width: '100%', paddingY: 1.2 }}
                                onClick={() => setShowAddTeamOwnerModal(true)}
                            >
                                Add Additional Managers
                            </Button>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Grid>
            <Box
                width={1}
                height={"1px"}
                bgcolor={"gray"}
                sx={{ opacity: 0.25 }}
                mt={1}
            ></Box>

            <Grid container spacing={3} sx={{
                mt: 4, mb: 4, px: 3,
                [theme.breakpoints.down("sm")]: {
                    mt: 1,
                    mb: 3,
                }
            }}>
                <Grid item xs={12} sm={6} lg={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                fontSize={20}
                                color={theme?.palette?.text?.containerTextLabel}
                                sx={{
                                    mb: -1.5,
                                }}
                            >
                                ADD DIVISONS
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                name="division"
                                labelId="division-lblid"
                                multiple
                                displayEmpty
                                value={selectedDivisions}
                                onChange={handleChangeDivision}
                                input={
                                    <OutlinedInput
                                        label=""
                                        placeholder="Select Division"
                                        sx={{ width: '100%', height: 45 }}
                                    />
                                }
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                renderValue={(selected) => {
                                    if (selected?.length && divisionList?.length) {
                                        let titles = [];
                                        divisionList?.filter((division) => {
                                            if (selected.includes(division?.id)) {
                                                titles.push(division?.name);
                                            }
                                        });
                                        if (titles?.length) {
                                            return titles.join(", ");
                                        }
                                        return selected?.join(", ");
                                    } else {
                                        return (
                                            <Typography
                                                variant="p"
                                                fontWeight={400}
                                                fontSize={14}
                                                sx={{ opacity: 0.5 }}
                                            >
                                                Select Division...
                                            </Typography>
                                        );
                                    }
                                }}
                                MenuProps={MenuProps}
                            >
                                {divisionList?.map((division) => (
                                    <MenuItem key={division?.id} value={division?.id}>
                                        <Checkbox
                                            checked={selectedDivisions.indexOf(division?.id) > -1}
                                        />
                                        <ListItemText primary={division?.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {Boolean(formik.touched.division && formik.errors.division) && (
                                <FormHelperText error>{formik.errors.division}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ paddingX: 3.5, width: '100%', paddingY: 1.2 }}
                                onClick={() => setShowAddDivisionModal(true)}
                            >
                                Add Division
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                fontSize={20}
                                color={theme?.palette?.text?.containerTextLabel}
                                sx={{
                                    mb: -1.5,
                                }}
                            >
                                OPEN TO GRADES
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                name="single_division"
                                labelId="org-filter"
                                // onBlur={formik.handleBlur}
                                onChange={(e) => handleChangeSingleDivision(e)}
                                value={selectedSingleDivision}
                                input={
                                    <OutlinedInput
                                        placeholder="Select Division"
                                        sx={selectionInputStyle}
                                    />
                                }
                                MenuProps={MenuProps}
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                displayEmpty
                                renderValue={(value) => {
                                    if (value) {
                                        let selected = singleDivisionList?.find((tm) => tm?.id === value);
                                        if (selected) {
                                            return selected?.name
                                        }
                                    } else {
                                        return (
                                            <Typography
                                                variant="p"
                                                fontWeight={400}
                                                fontSize={14}
                                                sx={{ opacity: 0.5 }}
                                            >
                                                Select Division...
                                            </Typography>
                                        );
                                    }
                                }}
                                className="MuiSelect-select"
                                sx={{
                                    "& .MuiSelect-select": {
                                        padding: "10.5px 14px"
                                    },
                                    width: '100%'
                                }}
                            >
                                {singleDivisionList?.map((division) => (
                                    <MenuItem key={division?.id} value={division?.id}>
                                        {division?.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                labelId="grade-lblid"
                                multiple
                                displayEmpty
                                value={selectedGrades}
                                onChange={handleChangeGrade}
                                input={
                                    <OutlinedInput
                                        label=""
                                        placeholder="Select Grades"
                                        sx={{ width: '100%', height: 45 }}
                                    />
                                }
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                renderValue={(selected) => {
                                    if (selected?.length && gradeList?.length) {
                                        let titles = [];
                                        gradeList?.filter((grade) => {
                                            if (selected.includes(grade?.id)) {
                                                titles.push(grade?.name);
                                            }
                                        });
                                        if (titles?.length) {
                                            return titles.join(", ");
                                        }
                                        return selected?.join(", ");
                                    } else {
                                        return (
                                            <Typography
                                                variant="p"
                                                fontWeight={400}
                                                fontSize={14}
                                                sx={{ opacity: 0.5 }}
                                            >
                                                Select Grades...
                                            </Typography>
                                        );
                                    }
                                }}
                                MenuProps={MenuProps}
                            >
                                {gradeList?.map((grade) => (
                                    <MenuItem key={grade?.id} value={grade?.id}>
                                        <Checkbox
                                            checked={selectedGrades.indexOf(grade?.id) > -1}
                                        />
                                        <ListItemText primary={grade?.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {Boolean(formik.touched.division_grade && formik.errors.division_grade) && (
                                <FormHelperText error>{formik.errors.division_grade}</FormHelperText>
                            )}
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                size="small"
                                variant="contained"
                                sx={{ paddingX: 3.5, width: '100%', paddingY: 1.2 }}
                                onClick={() => setShowAddGradeModal(true)}
                            >
                                Add Grade Type
                            </Button>
                        </Grid>
                        {/* <Grid item xs={10}>
                            <Typography
                                variant="h5"
                            >
                                {JSON.stringify(divisionGrade)}
                            </Typography>
                        </Grid> */}
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={6} lg={4}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Typography
                                variant="h4"
                                fontWeight={700}
                                fontSize={20}
                                color={theme?.palette?.text?.containerTextLabel}
                                sx={{
                                    mb: -1.5,
                                }}
                            >
                                DOCUMENTS REQUIRED
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Select
                                name="document"
                                labelId="document-lblid"
                                multiple
                                displayEmpty
                                value={selectedDocuments}
                                onChange={handleChangeDocument}
                                input={
                                    <OutlinedInput
                                        label=""
                                        placeholder="Select Documents"
                                        sx={{ width: '100%', height: 45 }}
                                    />
                                }
                                inputProps={{
                                    "aria-label": "Without label",
                                }}
                                renderValue={(selected) => {
                                    if (selected?.length && documentList?.length) {
                                        let titles = [];
                                        documentList?.filter((document) => {
                                            if (selected.includes(document?.value)) {
                                                titles.push(document?.name);
                                            }
                                        });
                                        if (titles?.length) {
                                            return titles.join(", ");
                                        }
                                        return selected?.join(", ");
                                    } else {
                                        return (
                                            <Typography
                                                variant="p"
                                                fontWeight={400}
                                                fontSize={14}
                                                sx={{ opacity: 0.5 }}
                                            >
                                                Select Documents...
                                            </Typography>
                                        );
                                    }
                                }}
                                MenuProps={MenuProps}
                            >
                                {documentList?.map((document) => (
                                    <MenuItem key={document?.value} value={document?.value}>
                                        <Checkbox
                                            checked={selectedDocuments.indexOf(document?.value) > -1}
                                        />
                                        <ListItemText primary={document?.name} />
                                    </MenuItem>
                                ))}
                            </Select>
                            {Boolean(formik.touched.document && formik.errors.document) && (
                                <FormHelperText error>{formik.errors.document}</FormHelperText>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Box
                width={1}
                height={"1px"}
                bgcolor={"gray"}
                sx={{ opacity: 0.25 }}
                mt={1}
            ></Box>

            <Grid container spacing={3} sx={{
                mt: 4, mb: 4, px: 3,
                [theme.breakpoints.down("sm")]: {
                    mt: 1,
                    mb: 3,
                }
            }}>
                {documentTypes?.map((doc) => (
                    <Grid item xs={12} sm={6} lg={4} key={doc?.id}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="h4"
                                    fontWeight={700}
                                    fontSize={20}
                                    color={theme?.palette?.text?.containerTextLabel}
                                    sx={{
                                        mb: -1.5,
                                    }}
                                >
                                    {doc?.type}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <FormControl
                                    fullWidth
                                    sx={{ my: 0, maxWidth: "100%", width: "100%", }}
                                >
                                    <InputLabel htmlFor="docImg" sx={{ fontSize: "14px" }} shrink={false}>
                                        {doc?.placeholder}
                                    </InputLabel>
                                    <OutlinedInput
                                        name={`doc_${doc?.id}`}
                                        id={`doc_${doc?.id}`}
                                        type={"file"}
                                        onChange={(e) => {
                                            handleChangeFileUpload(e, doc?.id);
                                        }}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="docImg"
                                                    edge="end"
                                                    size="large"
                                                    htmlFor={`doc_${doc?.id}`}
                                                    component="label"
                                                    sx={{
                                                        backgroundColor:
                                                            theme?.palette?.text?.containerTextLabel,
                                                        borderRadius: "0 3px 3px 0",
                                                        width: '50px',
                                                        left: "3px",
                                                        // ml: 'auto',
                                                        // mr: '-14px',
                                                        p: '10px'
                                                    }}
                                                >
                                                    <UploadFileRounded sx={{ color: "white" }} />
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        sx={{
                                            "& .MuiOutlinedInput-input": {
                                                padding: "12.5px 14px",
                                            },
                                            "& ::file-selector-button": {
                                                opacity: 0,
                                                display: "none",
                                            },
                                            '& .MuiInputAdornment-root': {
                                                width: 'auto',
                                                marginLeft: '0',
                                            }
                                        }}
                                        inputProps={{
                                            style: {
                                                opacity: 0,
                                            },
                                            accept: "application/pdf, image/*"
                                        }}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                ))}
            </Grid>

            {/* {showAddTeamOwnerModal && (
                <AddTournamentManager
                    open={showAddTeamOwnerModal}
                    handleClose={handleTeamOwnerModalClose}
                />
            )} */}

            {showAddDivisionModal && (
                <AddDivision
                    open={showAddDivisionModal}
                    handleClose={handleDivisionModalClose}
                />
            )}

            {showAddGradeModal && (
                <AddGrade
                    open={showAddGradeModal}
                    handleClose={handleGradeModalClose}
                />
            )}
        </>
    )
}
export default AdminTournamentForm;

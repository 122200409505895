import httpClient from "@utils/httpClient";
import axios from "axios";
import { getFormattedTime } from "../utils/functions";
import moment from "moment";

let orgToken = localStorage.getItem("orgToken");
let userToken = localStorage.getItem("userToken");
// action - theme reducer
export const SET_DRAWER = "@theme/SET_DRAWER";
export const SET_USER_DRAWER = "@theme/SET_USER_DRAWER";
export const SET_LOADER = "@theme/SET_LOADER";
export const SET_GOBACK = "@theme/SET_GOBACK";
export const SET_TOAST = "@theme/SET_TOAST";
export const SET_SUCCESS_ERROR_POPUP = "@theme/SET_SUCCESS_ERROR_POPUP";

export const goBack = (path) => {
  return async (dispatch) => {
    dispatch({ type: SET_GOBACK, data: path });
  };
};

// action - notification reducer
export const SET_NOTIFICATION_CONTENT =
  "@notification/SET_NOTIFICATION_CONTENT";

// action - org reducer
export const SET_ORG = "@user/SET_ORG";
export const getOrgData = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    let code = "";
    const host = window?.location?.hostname || "";
    // FOR LOCALHOST TESTING //
    if (host === "localhost") {
      // code = 'c2NyaXY=' // scriv (3)
      // code = 'NnBvaW50cw==' // 6points (2)
      // code = 'c2tldGNocGxheQ==' // sketchplay (1)
      code = "Z3JpZGlyb24="; // gridiron (4)
    }
    try {
      httpClient.defaults.headers.common["Authorization"] = "";
      httpClient.defaults.headers.common["X-ORG-TOKEN"] = "";
      const res = await httpClient.post("/orgSettings", {
        host,
        code,
      });
      if (res?.ok) {
        localStorage.setItem("orgToken", res?.token);
      }
      dispatch({ type: SET_ORG, data: res || null });
      dispatch({ type: SET_LOADER, data: false });
    } catch (error) {
      dispatch({ type: SET_LOADER, data: false });
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: "something went wrong",
        },
      });
    }
  };
};

// action - user reducer
export const SET_USER = "@user/SET_USER";
export const SET_UPDATE_USER = "@user/SET_UPDATE_USER";
export const SET_UPDATE_PLAYER_USER = "@user/SET_UPDATE_PLAYER_USER";
export const SET_UPDATE_SUBPARENT_USER = "@user/SET_UPDATE_SUBPARENT_USER";
export const SET_UPDATE_SUBATHLETE_USER = "@user/SET_UPDATE_SUBATHLETE_USER";
export const SET_USER_HISTORIC_PLAN = "@user/SET_USER_HISTORIC_PLAN";
export const SET_TOKEN = "@user/SET_TOKEN";
export const LOGOUT_USER = "@user/LOGOUT_USER";

// action - sketch reducer
export const SAVE_SVGDATA = "@sketch/SAVE_SVGDATA";
export const SET_SAVE_ACTION = "@sketch/SET_SAVE_ACTION";
export const SET_CLEAR_ACTION = "@sketch/SET_CLEAR_ACTION";

// action - buckets reducer
export const GET_BUCKET = "@buckets/GET_BUCKET";
export const SET_BUCKET = "@buckets/SET_BUCKET";
export const UPDATE_BUCKET = "@buckets/UPDATE_BUCKET";
export const getBucketListing = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/buckets?full=true");
    const buckets = res.data || [];
    dispatch({ type: SET_BUCKET, data: buckets });
    dispatch({ type: SET_LOADER, data: false });
  };
};

// action - player content reducer
export const GET_PLAYER_CONTENT = "@playerContent/GET_PLAYER_CONTENT";
export const SET_PLAYER_CONTENT = "@playerContent/SET_PLAYER_CONTENT";
export const UPDATE_PLAYER_CONTENT = "@playerContent/UPDATE_PLAYER_CONTENT";
export const ADD_PLAYER_CONTENT = "@playerContent/ADD_PLAYER_CONTENT";
export const GET_PLAYER_CONTENT_CATEGORY =
  "@playerContent/GET_PLAYER_CONTENT_CATEGORY";
export const SET_PLAYER_CONTENT_CATEGORY =
  "@playerContent/SET_PLAYER_CONTENT_CATEGORY";
export const UPDATE_PLAYER_CONTENT_CATEGORY =
  "@playerContent/UPDATE_PLAYER_CONTENT_CATEGORY";
export const ADD_PLAYER_CONTENT_CATEGORY =
  "@playerContent/ADD_PLAYER_CONTENT_CATEGORY";
export const getPlayerContent = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/player-contents");
    const contents = res.data || [];
    dispatch({ type: SET_PLAYER_CONTENT, data: contents });
    dispatch({ type: SET_LOADER, data: false });
  };
};
export const getPlayerContentCategories = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/player-content-categories");
    const categories = res.data || [];
    dispatch({ type: SET_PLAYER_CONTENT_CATEGORY, data: categories });
    dispatch({ type: SET_LOADER, data: false });
  };
};

// action - Gym Section reducer
export const SET_FILM_ROOM_CONTENT = "@playerContent/SET_FILM_ROOM_CONTENT";
export const SET_FILM_ROOM_PLANS = "@playerContent/SET_FILM_ROOM_PLANS";
export const getFilmRoomContent = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/filmRoom");
    const contents = res.data || [];
    dispatch({ type: SET_FILM_ROOM_CONTENT, data: contents });
    dispatch({ type: SET_LOADER, data: false });
  };
};
export const getFilmRoomPlans = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/filmRoomPlans");
    const plans = res.data || [];
    dispatch({ type: SET_FILM_ROOM_PLANS, data: plans });
    dispatch({ type: SET_LOADER, data: false });
  };
};
export const getMe = () => {
  return async (dispatch) => {
    // dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/users/me");
    // dispatch({ type: SET_LOADER, data: false });
    const { user } = res;
    if (user) {
      dispatch({ type: SET_USER, data: user });
    }
  };
};

export const ADD_PLAYER_SESSION = "@playerContent/ADD_PLAYER_SESSION";

export const SET_PLAYER_SESSION_ID = "@playerContent/SET_PLAYER_SESSION_ID";
export const getPlayerContentById = (id) => {
  return async (dispatch) => {
    //dispatch({ type: SET_LOADER, data: true })
    const res = await httpClient.get("/player-contents/" + id);
    const contents = res.data || [];
    dispatch({ type: SET_PLAYER_SESSION_ID, data: contents });
    //dispatch({ type: SET_LOADER, data: false })
  };
};

export const SET_SOCIAL_POST = "@social/SET_SOCIAL_POST";
export const CREATE_SOCIAL_POST = "@social/CREATE_SOCIAL_POST";
export const CREATE_SOCIAL_COMMENT = "@social/CREATE_SOCIAL_COMMENT";
export const CREATE_SOCIAL_LIKE = "@social/CREATE_SOCIAL_LIKE";
export const SET_SOCIAL_COMMENT_LIST = "@social/SET_SOCIAL_COMMENT_LIST";
export const SET_SOCIAL_POSTID = "@social/SET_SOCIAL_POSTID";

export const getSocialPost = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/social-posts");
    const contents = res.data || [];
    dispatch({ type: SET_SOCIAL_POST, data: contents });
    dispatch({ type: SET_LOADER, data: false });
  };
};

export const getHistoricPlan = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/historic-plan");
    dispatch({ type: SET_USER_HISTORIC_PLAN, planData: res?.data });
    dispatch({ type: SET_LOADER, data: false });
  };
};

export const getSocialPostId = (postId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/social-posts/" + postId);
    const contents = res.data?.post || {};
    dispatch({ type: SET_SOCIAL_POSTID, data: contents });
    dispatch({ type: SET_LOADER, data: false });
  };
};

export const getSocialCommentsListByPostId = (postId) => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/social-post-comments/" + postId);
    const contents = res.data || [];
    dispatch({ type: SET_SOCIAL_COMMENT_LIST, data: contents });
    dispatch({ type: SET_LOADER, data: false });
  };
};

export const SET_PLAYER_EVENT = "@event/SET_PLAYER_EVENT";
export const ADD_PLAYER_EVENT = "@event/ADD_PLAYER_EVENT";
export const EDIT_PLAYER_EVENT = "@event/EDIT_PLAYER_EVENT";

export const dateFormat = (dateStr, timeStr) => {
  const [year, month, day] = dateStr.split("-").map(Number);
  const timeParts = timeStr.match(/(\d+):(\d+) (AM|PM)/);

  if (!timeParts) {
    throw new Error("Invalid time format. Please use 'HH:MM AM/PM' format.");
  }

  const [_, hours, minutes, period] = timeParts;

  const formattedHours =
    period === "AM" && hours === "12"
      ? "00"
      : period === "PM"
      ? String(Number(hours) + 12)
      : hours;

  const newDate = new Date();
  newDate.setFullYear(year);
  newDate.setMonth(month - 1);
  newDate.setDate(day);
  newDate.setHours(Number(formattedHours));
  newDate.setMinutes(Number(minutes));
  newDate.setSeconds(0);
  newDate.setMilliseconds(0);
  return newDate;
};

export const getAllEvents = (params) => {
  return async (dispatch) => {
    let {
      classType,
      staffMember,
      position,
      startDate,
      athlete_id,
      showOnlyRegisteredEvent,
    } = params;

    httpClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${params?.userToken}`;
    httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get(
      `/${
        showOnlyRegisteredEvent ? "allRegisteredEvents" : "allEvents"
      }?class_type=${
        classType === "All" || !classType ? "" : classType
      }&staff_member=${
        staffMember === "All" || !staffMember ? "" : staffMember
      }&position=${
        position === "All" || !position ? "" : position
      }&start_date=${startDate}&athlete_id=${athlete_id}`,
    );
    dispatch({ type: SET_LOADER, data: false });
    if (res?.ok) {
      const formattedEventList = res.data.map((event) => ({
        ...event,
        // start: dateFormat(event?.start_date, event?.start_time),
        // end: dateFormat(event?.end_date, event?.end_time),
        start: new Date(event?.start_date + " " + event?.start_time),
        end: new Date(event?.end_date + " " + event?.end_time),
      }));
      const events = formattedEventList || [];
      dispatch({ type: SET_PLAYER_EVENT, data: events });
    }
  };
};

export const SET_EVENT_LOCATIONS = "@event/SET_EVENT_LOCATIONS";
export const ADD_EVENT_LOCATIONS = "@event/ADD_EVENT_LOCATIONS";
export const UPDATE_EVENT_LOCATIONS = "@event/UPDATE_EVENT_LOCATIONS";

export const getEventLocations = (params) => {
  return async (dispatch) => {
    httpClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${params?.userToken}`;
    httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/event-locations");
    const locations = res.data || [];
    dispatch({ type: SET_EVENT_LOCATIONS, data: locations });
    dispatch({ type: SET_LOADER, data: false });
  };
};

//gridiron signup
export const SET_ATHLETEARR = "@athleteDataArray/SET_ATHLETEARR";
export const ADD_ATHLETEARR = "@athleteDataArray/ADD_ATHLETEARR";
export const UPDATE_ATHLETEARR = "@athleteDataArray/UPDATE_ATHLETEARR";

export const getAdminUsers = async (params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get("/getAdmin");
  return response;
};

export const getUsers = async (dispatch, params) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/userList?skip=${params?.skip}&limit=${params?.limit}&keyword=${params?.searchKeyword}&user_type=${params?.usertype}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllSubOrg = async (params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getAllSubOrg?skip=${params?.skip}&limit=${params?.limit}`,
  );
  return response;
};

export const getAllContacts = async (params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/contacts?skip=${params?.skip}&limit=${params?.limit}`,
  );
  return response;
};

export const postSubOrg = async (params, bodyParams) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/createSubOrg", bodyParams);
  return response;
};
export const addAdminUser = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/addAdmin", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const deleteAdminUser = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/deleteAdmin", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const deleteSubOrg = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/deleteSubOrg", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getSubOrgTeams = async (params, query) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getAllSubOrgTeam?skip=${params?.skip}&limit=${params?.limit}&id=${
      query ? query : ""
    }`,
  );
  return response;
};

export const createSubOrgTeam = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/createSubOrgTeam", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const deleteSubOrgTeam = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/deleteSubOrgTeam", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const userSupportContact = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/contacts", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getCoach = async ({ dispatch, params }) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getCoach?skip=${params?.skip}&limit=${params?.limit}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const addCoachAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/addCoach", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const deleteCoachAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/deleteCoach", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const uploadFileAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/auth/uploadFile", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const createTeamRequestAction = async (dispatch, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${orgToken}`;
  const response = await httpClient.post("/createRequest", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllRequestAction = async (params, query) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getAllRequest?skip=${params?.skip}&limit=${params?.limit}`,
  );
  return response;
};

export const acceptDenyRequestAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/acceptRequest", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

//products
export const SET_PRODUCTS = "@product/SET_PRODUCTS";
export const SET_PRODUCT_CATEGORY = "@product/SET_PRODUCT_CATEGORY";
export const ADD_PRODUCT_CATEGORY = "@product/ADD_PRODUCT_CATEGORY";

export const getProductCategories = () => {
  return async (dispatch) => {
    dispatch({ type: SET_LOADER, data: true });
    const res = await httpClient.get("/product-categories");
    const categories = res.data || [];
    dispatch({ type: SET_PRODUCT_CATEGORY, data: categories });
    dispatch({ type: SET_LOADER, data: false });
  };
};

export const getProducts = async (dispatch, params) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const res = await httpClient.get(
    `/products?skip=${params?.skip}&limit=${params?.limit}&is_active=true&type=${params?.type}&grade=${params?.grade}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const getTeamCoachAction = async (params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getTeamCoach`);
  return response;
};

export const createTeamByTeamManagerAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/createTeamByTeamManager", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getTeamManagerAction = async (params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getTeamManagerAndTeamCoach`);
  return response;
};

export const createTeamManagerAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/addTeamManager", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const createTeamCoachAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/addTeamCoach", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const createTeamRosterIndividualAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/addTeamRoster", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAthleteTemplateAction = async (params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getAthleteTemplate`);
  return response;
};

export const uploadAthleteInfoAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/uploadAthleteInfo", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const uploadDocumentAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/auth/uploadDocument", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const updateRosterDocumentAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/updateRosterDocument", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const deleteRosterDocumentAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/deleteRosterDocument", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getTournamentTeamMemberAction = async (params, teamId) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getPaidTeamMemberList?id=${teamId}`);
  return response;
};

export const verifyRosterDocumentAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/verifyRosterDocument", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const updateTeamAthleteDetailsAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post(
    "/UpdateTeamAthleteDetails",
    bodyParam,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAthleteListPdfAction = async (params, teamId) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getAthleteListPdf?id=${teamId}`);
  return response;
};

export const getDivisions = async (dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  const res = await httpClient.get("/getAllDivision");
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const getGrades = async (params, dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const res = await httpClient.get("/getAllGrade");
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const getTournamentManagers = async (dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  const res = await httpClient.get("/getAdmin");
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const getProductsByType = async (dispatch, params) => {
  dispatch({ type: SET_LOADER, data: true });
  const res = await httpClient.get(
    `/products?skip=${params?.skip}&limit=${params?.limit}&type=${params?.type}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const getDocuments = async (dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  const res = await httpClient.get("/getAllDocument");
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const getTournaments = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const res = await httpClient.get(
    `/getAllTournament?skip=${params?.skip}&limit=${params?.limit}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return res;
};
export const getAllTeamCoachAction = async (params, subOrgId) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getTeamCoach?orgId=${subOrgId ?? ""}`,
  );
  return response;
};

export const getAllTeamManagerAction = async (params, subOrgId, dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getTeamManager?orgId=${subOrgId ?? ""}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const deleteTeamCoachAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/deleteTeamCoach", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const deleteTeamManagerAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/deleteTeamManager", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getUnPaidTeamMemberListAction = async (params, teamId) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getUnPaidTeamMemberList?id=${teamId}`,
  );
  return response;
};

export const saveRosterAction = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post(
    "/athlete-payments/addAthlete",
    bodyParam,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllTeamsAction = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    `/getAllTeam?skip=${params?.skip}&limit=${params?.limit}&tournament_id=${params?.tournamentId}&sub_organization_id=${params?.subOrgId}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getTournamentTeams = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const res = await httpClient.get(
    `/getAllTournamentTeam?skip=${params?.skip}&limit=${params?.limit}&id=${params?.tournamentId}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const getAllTournamentTeams = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const res = await httpClient.get(
    "/getAllTournamentTeams?id=" + params?.tournamentId,
  );
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const deleteTeamRosterAction = async (dispatch, params, bodyParam) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post(
    "/athlete-payments/removeAthlete",
    bodyParam,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const applyTeamDiscountCodeAction = async (
  dispatch,
  params,
  queryParam,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    `/checkDiscount?name=${queryParam?.code}&amount=${queryParam?.total_amount}&tournament_id=${queryParam?.tournament_id}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllMessagesAction = async (params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getAllCommunicate?skip=${params?.skip}&limit=${params?.limit}`,
  );
  return response;
};

export const addUpdateAdminMessageAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/createCommunicate", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const deleteAdminMessageAction = async (dispatch, params, bodyParam) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/deleteCommunicate", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const checkParentUserForScholarshipApplyAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/checkParentUser", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const checkAthleteUsersForScholarshipApplyAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/checkAthleteUsers", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const addScholarshipApplyAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/addScholarship", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAppMessageAction = async ({ params, dispatch }) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get("/getCommunicate");
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getTournamentScores = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    "/getAllTournamentScore?id=" + params?.tournamentId,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllScholarshipAction = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getAllScholarship?skip=${params?.skip}&limit=${params?.limit}&keyword=${params?.searchKeyword}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getScholarshipDiscountsAction = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get("/getScholarshipDiscount");
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const approveScholarshipRequestAction = async (
  dispatch,
  params,
  bodyParam,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/approveScholarship", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllUsersToCSV = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get("/getAllUsersCSV");
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllTournamentTeamToCSV = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    "/getAllTournamentReportTeamCSV?id=" + params.tournamentId,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllHelpVideos = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get("/getAllHelpVideo");
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllProductPackages = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    `/productsPackage?isMobile=false&type=${params?.productType}&grade=${params?.grade}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const applyProductDiscountCodeAction = async (
  dispatch,
  params,
  bodyParams,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post(
    "/checkSingleProductDiscount",
    bodyParams,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getMyProductPacksAction = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get("/getMyPackDetails");
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getRoleTypes = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get("/getAllRoleType");
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const saveOrgSettingsAction = async (dispatch, params, bodyParams) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/updateOrgSettings", bodyParams);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const updateOrgEmailAPISettings = async (
  dispatch,
  params,
  bodyParams,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/updateOrgEmailSettings", bodyParams);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllProductTypeAction = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get("/getAllProductType");
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllProduct = async (params, dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(`/products/${params?.productId}`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const addEventLocationsAction = async (dispatch, params, bodyParams) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/event-locations", bodyParams);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const updateUserDetails = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/auth/update-user-detail", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const createUpdateEvent = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post(
    params?.isUpdate ? "updateEvent" : "/events",
    bodyParam,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getEvents = async (dispatch, params) => {
  let { dailytraining, staffMember, position } = params;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    `/events?class_type=${dailytraining}&staff_member=${staffMember}&position=${position}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getEventTypesAction = async (dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common["Authorization"] = `Bearer ${userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${orgToken}`;
  const response = await httpClient.get(`/getAllEventType`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllPositionsAction = async ({ dispatch, params }) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getAllPosition`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllPositionsList = async ({ dispatch, params }) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getAllPositions`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const checkEligibleForEvent = async ({ dispatch, params }) => {
  let { eventId, athleteId } = params;
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    `/checkEligibleEvent?event_id=${eventId}&athlete_id=${athleteId}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const checkEligibleForProduct = async ({ dispatch, params }) => {
  let { eventId, athleteId } = params;
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    `/checkEligibleProduct?event_id=${eventId}&athlete_id=${athleteId}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const updateUserWallet = async (dispatch, params, bodyParam) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/addCredit", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};
export const registerEventAction = async ({
  dispatch,
  bodyParam,
  registerWith,
  tokenParams,
}) => {
  let endPoint = "/registerEvent";
  if (registerWith === "PACK") {
    endPoint = "/registerEventWithPack";
  }
  if (registerWith === "FREE") {
    endPoint = "/registerFreeEvent";
  }
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${tokenParams?.userToken}`;
  httpClient.defaults.headers.common[
    "X-ORG-TOKEN"
  ] = `${tokenParams?.orgToken}`;
  const response = await httpClient.post(endPoint, bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getWalletHistory = async (dispatch, param) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${param?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${param?.orgToken}`;
  const response = await httpClient.get(
    `/walletHistory?skip=${param.skip}&limit=${param.limit}&user_id=${param.id}&debit_type=${param.debit_type}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getPackPurchaseHistory = async (dispatch, param) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${param?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${param?.orgToken}`;
  const response = await httpClient.get(
    `/getPurchaseInfo?user_id=${param.id}&type=${param?.type}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const registerEventPaymentIntentAction = async ({
  dispatch,
  bodyParam,
  params,
}) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.post("/events/paymentIntent", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const checkEventDiscountAction = async ({
  dispatch,
  bodyParam,
  params,
}) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post(`/checkEventDiscount`, bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllEventDiscountAction = async ({ dispatch, params }) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getAllDiscountEvent?is_active=true`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const createUpdateEventDiscountCodeAction = async ({
  dispatch,
  params,
  bodyParam,
}) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/createDiscountEvent", bodyParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllTimezoneAction = async (dispatch, params) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(`/getAllTimezone`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getallEventsSprintActions = async ({ dispatch, params }) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/allEventsSprint?sprint_start_date=${params?.currentDate}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllEventSprintAction = async ({ dispatch, params }) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const response = await httpClient.get(
    `/getAllEventSprint?latest=${
      typeof params?.latest === "undefined" ? false : params?.latest
    }`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const addEventSprintAction = async (dispatch, params, bodyParams) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/createEventSprint", bodyParams);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getViewEventRegisterUsers = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const res = await httpClient.get(
    `/registeredEventAthlete?event_id=${params.eventId}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const getExportReportToCSV = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  let urlParam = `${params?.url}?start_date=${
    params?.start_date ?? ""
  }&end_date=${params?.end_date ?? ""}`;
  if (params?.selectedReport === 2) {
    urlParam = `${params?.url}&start_date=${
      params?.start_date ?? ""
    }&end_date=${params?.end_date ?? ""}`;
  }
  const response = await httpClient.get(urlParam);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getEventListForReport = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(`/eventList`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getEventDetails = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    `/getEventDetail?event_id=${params?.eventId}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getEventDetailsNotSignin = async (params, dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(
    `/getEventDetails?event_id=${params?.eventId}`,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getUserWalletDetailsAction = async (params, dispatch) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(`/users/wallet`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const updateUserEventStatusAction = async (
  dispatch,
  params,
  bodyParams,
) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post(
    "/event-players/userEventStatus",
    bodyParams,
  );
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllTemplates = async (dispatch, params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(`/getAllTemplateList`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllClientsList = async (dispatch, params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(`/getAllClientsList`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getAllCampaignAdminList = async (dispatch, params) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.get(`/getAllAdminList`);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

export const getLeagues = async (params, dispatch) => {
  dispatch({ type: SET_LOADER, data: true });
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  const res = await httpClient.get("/getAllLeagueTeam");
  dispatch({ type: SET_LOADER, data: false });
  return res;
};

export const leagueTeamRegistration = async (dispatch, params, bodyParams) => {
  httpClient.defaults.headers.common[
    "Authorization"
  ] = `Bearer ${params?.userToken}`;
  httpClient.defaults.headers.common["X-ORG-TOKEN"] = `${params?.orgToken}`;
  dispatch({ type: SET_LOADER, data: true });
  const response = await httpClient.post("/leagueRegistration", bodyParams);
  dispatch({ type: SET_LOADER, data: false });
  return response;
};

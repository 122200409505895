import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Grid,
  InputLabel,
  Popover,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useRef } from "react";
import {
  SET_LOADER,
  SET_TOAST,
  deleteSubOrg,
  getAllSubOrg,
  postSubOrg,
} from "../../store/actions";
import MuiDataGrid from "../../components/MuiDataGrid/MuiDataGrid";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import SuccessPopup from "../../components/Common/SuccessPopup";
import CustomTextField from "../../components/CustomTextField";
import { CustomInputLabel } from "../../components/Common/Inputs/InputLabel";
import PhoneInput from "react-phone-input-2";
import StarIcon from "@mui/icons-material/Star";
import ActionCard from "../../components/Common/ActionCard";
import DeleteConfirmPopup from "../../components/Common/DeleteConfirm";
import CancelConfirmDialog from "../../components/Common/CancelConfirmDialog";
import { useNavigate } from "react-router-dom";
import { SUB_ORGANIZATIONS_TEAM_PAGE } from "../../routes/constants";
import { charValidate } from '@utils/functions';
import { convertToCapsOfFirstLetter } from "@utils/functions";
import { phoneFormat } from "../../utils/functions";

const validationSchema = Yup.object({
  suborg: Yup.string().required("Sub-Organization is required")
    .test(
      "suborg",
      "Invalid value",
      (value) => /^\s*\s*$/.test(String(value)) ? false : true,
    ),
  access: Yup.string().required("Access Code is required")
    .test(
      "access",
      "Invalid value",
      (value) => /^\s*\s*$/.test(String(value)) ? false : true,
    ),
  firstName: Yup.string().required("First Name is required")
    .test(
      "firstName",
      "Invalid value",
      (value) => /^\s*\s*$/.test(String(value)) ? false : true,
    ),
  last_Name: Yup.string().required("Last Name is required")
    .test(
      "last_Name",
      "Invalid value",
      (value) => /^\s*\s*$/.test(String(value)) ? false : true,
    ),
  email: Yup.string()
    .required("Email is required")
    .email("Must be a valid email")
    .test(
      "email",
      "Invalid value",
      (value) => /^\s*\s*$/.test(String(value)) ? false : true,
    ),
  mobile: Yup.string().required("Mobile is required")
    .test(
      "mobile",
      "Invalid value",
      (value) => /^\s*\s*$/.test(String(value)) ? false : true,
    ),
  // countryCode: Yup.string().required("Country Code is required"),
  // communityCheckBox: Yup.string().required("community Check Box is required"),
  // restrictCheckBox: Yup.string().required("Restrict Check Box is required"),
});

const SubOrganizations = () => {
  const tableRef = useRef();
  const [subOrgs, setSubOrgs] = useState();
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedDataGridRow, setSelectedDataGridRow] = useState();
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [checkCommunity, setCheckCommunity] = useState(selectedDataGridRow?.permission?.community ?? false);
  const [checkPost, setCheckPost] = useState(selectedDataGridRow?.permission?.post ?? false);
  const [showCancelSaveConfirmPopup, setShowCancelSaveConfirmPopup] =
    useState(false);
  const [paginationOptions, setPaginationOptions] = useState({
    pageSize: 0,
    pageNum: 0,
    offset: 0,
    limit: 10,
  });
  const inputLabelStyle = { fontSize: 12, marginBottom: "5px", color: "gray" };
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const state = useSelector((state) => state);
  const navigate = useNavigate();
  const theme = useTheme();
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const openPopover = Boolean(popoverAnchorEl);
  const id = openPopover ? "simple-popover" : undefined;

  const formik = useFormik({
    initialValues: {
      suborg: selectedDataGridRow?.title ?? "",
      access: selectedDataGridRow?.org_code ?? "",
      firstName: selectedDataGridRow?.primary_contact?.first_name ?? "",
      last_Name: selectedDataGridRow?.primary_contact?.last_name ?? "",
      email: selectedDataGridRow?.primary_contact?.email ?? "",
      mobile: selectedDataGridRow?.primary_contact?.mobile ?? "",
      countryCode: selectedDataGridRow?.primary_contact?.countryCode ?? "US",
      // communityCheckBox: selectedDataGridRow?.permission?.community ?? false,
      // restrictCheckBox: selectedDataGridRow?.permission?.post ?? false,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const postData = {
        id: selectedDataGridRow?.id ?? undefined,
        title: values?.suborg,
        org_code: values?.access,
        primary_contact: {
          first_name: values?.firstName,
          last_name: values?.last_Name,
          email: values?.email,
          countryCode: values?.countryCode?.toUpperCase() ?? "US",
          mobile: values?.mobile,
        },
        permission: {
          community: checkCommunity,
          post: checkPost,
        },
      };
      addSubOrg(postData);
    },
  });

  useEffect(() => {
    if (selectedDataGridRow) {
      let values = {
        suborg: selectedDataGridRow?.title ?? "",
        access: selectedDataGridRow?.org_code ?? "",
        firstName: selectedDataGridRow?.primary_contact?.first_name ?? "",
        last_Name: selectedDataGridRow?.primary_contact?.last_name ?? "",
        email: selectedDataGridRow?.primary_contact?.email ?? "",
        mobile: selectedDataGridRow?.primary_contact?.mobile ?? "",
        countryCode: selectedDataGridRow?.primary_contact?.countryCode ?? "US",
        // communityCheckBox: selectedDataGridRow?.permission?.community ?? false,
        // restrictCheckBox: selectedDataGridRow?.permission?.post ?? false,
      };
      formik.setFieldValue("suborg", values.suborg);
      formik.setFieldValue("access", values.access);
      formik.setFieldValue("firstName", values.firstName);
      formik.setFieldValue("last_Name", values.last_Name);
      formik.setFieldValue("email", values.email);
      formik.setFieldValue("mobile", values.mobile);
      formik.setFieldValue("countryCode", values.countryCode);
      formik.setFieldValue("firstName", values.firstName);
      setCheckCommunity(selectedDataGridRow?.permission?.community ?? false);
      setCheckPost(selectedDataGridRow?.permission?.post ?? false);
    }
  }, [selectedDataGridRow]);

  const addSubOrg = async (bodyParams) => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    dispatch({ type: SET_LOADER, data: true });
    let response = await postSubOrg(params, bodyParams);
    if (response?.ok) {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "success",
          message: response?.message || "Sub Org added successfully!",
        },
      });
      dispatch({ type: SET_LOADER, data: false });
      // setOpen(false)
      setStep(1);
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response?.message || "Something went wrong. Please try again!",
        },
      });
      dispatch({ type: SET_LOADER, data: false });
    }
  };

  useEffect(() => {
    if (state?.user?.token && state?.org?.org?.token) {
      getAllSubOrgList();
    }
  }, [state?.user?.token, state?.org?.org?.token]);

  const getAllSubOrgList = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
      skip: paginationOptions?.offset ?? 0,
      limit: paginationOptions?.limit ?? 10,
    };
    let response = await getAllSubOrg(params);
    if (response?.ok) {
      setSubOrgs(response?.data?.data ?? []);
      setPaginationOptions({
        totalMainDocs: response?.data?.totalMainDocs ?? 0,
        totalDocs: response?.data?.totalDocs ?? 0,
        offset: response?.data?.offset ?? 0,
        limit: response?.data?.limit ?? 10,
        totalPages: response?.data?.totalPages ?? 0,
        currentPage: response?.data?.currentPage ?? 0,
        hasPrevPage: response?.data?.hasPrevPage ?? false,
        hasNextPage: response?.data?.hasNextPage ?? false,
      });
    }
  };

  const handlePagination = (values) => {
    let page = values?.page;
    let pageSize = values?.pageSize;
    paginationOptions.offset = page * pageSize
    paginationOptions.limit = pageSize ?? 10
    getAllSubOrgList();
  };

  const handleClose = (isShowConfirmationPopup) => {
    setOpen(false);
    if (isShowConfirmationPopup) {
      setShowCancelSaveConfirmPopup(true);
    }
    setStep(0);
    getAllSubOrgList();
  };

  const columns = [
    {
      field: "title",
      headerName: "SUB-ORGANIZATION",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (data) => data?.row?.title ?? "N/A",
    },
    {
      field: "first_name",
      headerName: "PRIMARY CONTACT FIRST NAME",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (data) => data?.row?.primary_contact?.first_name ?? "N/A",
    },
    {
      field: "last_name",
      headerName: "PRIMARY CONTACT LAST NAME",
      flex: 1,
      minWidth: 120,
      sortable: false,
      renderCell: (data) => data?.row?.primary_contact?.last_name ?? "N/A",
    },
    {
      field: "email",
      headerName: "PRIMARY CONTACT EMAIL",
      flex: 1.4,
      minWidth: 170,
      sortable: false,
      renderCell: (data) => (
        <Tooltip
          title={
            data?.row?.primary_contact?.email ? <h4>{data?.row?.primary_contact?.email}</h4> : "N/A"
          }
        >
          <span>
            {data?.row?.primary_contact?.email
              ? charValidate(data?.row?.primary_contact?.email, 25)
              : "N/A"}
          </span>
        </Tooltip>
      ),
    },
    {
      field: "contact",
      headerName: "PRIMARY CONTACT MOBILE",
      renderCell: (data) => data?.row?.primary_contact?.mobile == null || "" ? "N/A" : phoneFormat(data?.row?.primary_contact?.mobile),
      flex: 1,
      minWidth: 150,
      sortable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 1,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (data) => {
        return (
          <Box>
            <SettingsIcon
              onClick={(e) => setPopoverAnchorEl(e?.currentTarget)}
              sx={{ fontSize: 22, cursor: "pointer" }}
              color="primary"
            />
            <Popover
              id={id}
              open={openPopover}
              anchorEl={popoverAnchorEl}
              onClick={() => setPopoverAnchorEl(null)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              sx={{
                p: 1,
                "& .MuiPaper-root": {
                  boxShadow: "0px 0px 8px rgba(0, 0, 0, 0.03)",
                },
              }}
            >
              <ActionCard title={"Sub-Org Details"}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingY: 1.2 }}
                    onClick={handleEditClick}
                  >
                    Edit Details
                  </Button>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{ paddingY: 1.2, mt: 2 }}
                    onClick={() => {
                      navigate(SUB_ORGANIZATIONS_TEAM_PAGE);
                    }}
                  >
                    Manage this Sub-Organizations Teams
                  </Button>
                  <Box
                    width={1}
                    height={"1px"}
                    bgcolor={"gray"}
                    sx={{ opacity: 0.25, mt: 3, mb: 1 }}
                  ></Box>
                  <Button
                    size="small"
                    variant="contained"
                    fullWidth
                    sx={{
                      mt: 2,
                      paddingY: 1.2,
                      background: theme?.palette?.error?.dark,
                    }}
                    onClick={handleDeleteClick}
                  >
                    Delete this Sub-Org
                  </Button>
                </Box>
              </ActionCard>
            </Popover>
          </Box>
        );
      },
    },
  ];

  const handleEditClick = () => {
    setPopoverAnchorEl(null);
    setOpen(true);
  };

  const handleDeleteClick = () => {
    setPopoverAnchorEl(null);
    setShowDeletePopup(true);
  };

  const handleConfirmDelete = async () => {
    let params = {
      userToken: state?.user?.token,
      orgToken: state?.org?.org?.token,
    };
    let response = await deleteSubOrg(dispatch, params, {
      id: selectedDataGridRow?.id,
    });
    if (response?.ok) {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "success",
          message: response.message,
        },
      });
      setShowDeletePopup(false);
      setShowSuccessPopup(true);
      setSelectedDataGridRow("");
      getAllSubOrgList();
    } else {
      dispatch({
        type: SET_TOAST,
        data: {
          type: "error",
          message: response.message ?? "Something went wrong",
        },
      });
    }
  };

  return (
    <>
      <MuiDataGrid
        headerLabel={"Sub Organizations"}
        gridData={subOrgs}
        columns={columns}
        paginationOptions={paginationOptions}
        handlePagination={handlePagination}
        tableRef={tableRef}
        isLoading={subOrgs ? false : true}
        onClickAddNew={() => { setOpen(true); formik.resetForm(); }}
        handleRowClick={(data) => setSelectedDataGridRow(data?.row)}
      />

      <Dialog
        open={open}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
        fullWidth
        maxWidth="md"
      >
        <DialogTitle sx={{ p: 3 }}>
          <Typography variant="h3" fontWeight={700}>
            Sub-Organization
          </Typography>
        </DialogTitle>
        <Divider></Divider>
        <form onSubmit={formik.handleSubmit}>
          <DialogContent sx={{ p: 3 }}>
            {step === 0 && (
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ mb: -1, }}>Sub-Organization Details</Typography>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomInputLabel htmlFor="suborg" sx={inputLabelStyle}>
                    SUB ORGANIZATION NAME
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.suborg && formik.errors.suborg,
                    )}
                  >
                    <CustomTextField
                      id="suborg"
                      name="suborg"
                      placeholder="Enter sub org. name..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.suborg}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: "100%",
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(formik.touched.suborg && formik.errors.suborg) && (
                      <FormHelperText error>
                        {formik.errors.suborg}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomInputLabel htmlFor="access" sx={inputLabelStyle}>
                    ACCESS CODE
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.access && formik.errors.access,
                    )}
                  >
                    <CustomTextField
                      id="access"
                      name="access"
                      placeholder="Enter access code..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.access}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: "100%",
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(formik.touched.access && formik.errors.access) && (
                      <FormHelperText error>
                        {formik.errors.access}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Box display="flex" alignItems="center">
                    <Typography variant="h4" sx={{ mb: -1, }}>Primary Contact</Typography>
                    <StarIcon sx={{ color: "#F0CB00", fontSize: 22, ml: 1 }} />
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomInputLabel htmlFor="firstName" sx={inputLabelStyle}>
                    first name
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.firstName && formik.errors.firstName,
                    )}
                  >
                    <CustomTextField
                      id="firstName"
                      name="firstName"
                      placeholder="Enter first name..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'firstName')}
                      value={formik.values.firstName}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: "100%",
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(
                      formik.touched.firstName && formik.errors.firstName,
                    ) && (
                        <FormHelperText error>
                          {formik.errors.firstName}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomInputLabel htmlFor="last_Name" sx={inputLabelStyle}>
                    last name
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.last_Name && formik.errors.last_Name,
                    )}
                  >
                    <CustomTextField
                      id="last_Name"
                      name="last_Name"
                      placeholder="Enter last name..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={(e) => convertToCapsOfFirstLetter(e, formik, 'last_Name')}
                      value={formik.values.last_Name}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: "100%",
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(
                      formik.touched.last_Name && formik.errors.last_Name,
                    ) && (
                        <FormHelperText error>
                          {formik.errors.last_Name}
                        </FormHelperText>
                      )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomInputLabel htmlFor="last_Name" sx={inputLabelStyle}>
                    email address
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(formik.touched.email && formik.errors.email)}
                  >
                    <CustomTextField
                      id="email"
                      name="email"
                      placeholder="Enter email..."
                      variant="outlined"
                      size="small"
                      onBlur={formik.handleBlur}
                      onChange={formik.handleChange}
                      value={formik.values.email.toLowerCase()}
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          border: `1px solid ${theme?.palette?.grey?.[400]}`,
                          width: "100%",
                          boxShadow: "none",
                        },
                      }}
                    />
                    {Boolean(formik.touched.email && formik.errors.email) && (
                      <FormHelperText error>
                        {formik.errors.email}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid item sm={6} xs={12}>
                  <CustomInputLabel htmlFor="mobile" sx={inputLabelStyle}>
                    mobile number
                  </CustomInputLabel>
                  <FormControl
                    fullWidth
                    error={Boolean(
                      formik.touched.mobile && formik.errors.mobile,
                    )}
                  >
                    <Box
                      sx={{
                        "& .react-tel-input .form-control": {
                          minWidth: "100%",
                          fontWeight: 500,
                          background: theme?.palette?.grey[50],
                          height: "44px",
                          width: "100%",
                        },
                      }}
                    >
                      <PhoneInput
                        country={"us"}
                        id="mobile"
                        name="mobile"
                        variant="outlined"
                        placeholder="(702) 123-4567"
                        onBlur={formik.handleBlur}
                        value={formik.values.mobile}
                        onChange={(value, country) => {
                          formik.setFieldValue(
                            "countryCode",
                            country?.countryCode || "US",
                          );
                          formik.setFieldValue("mobile", value || "");
                        }}
                        error={Boolean(
                          formik.touched.mobile && formik.errors.mobile,
                        )}
                        size="sm"
                        style={{
                          width: "100%",
                        }}
                        sx={{
                          "& .MuiOutlinedInput-input": {
                            padding: "12px 14px",
                          },
                        }}
                        disableDropdown={true}
                        disableCountryCode={true}
                        onlyCountries={["us"]}
                      />
                      {Boolean(
                        formik.touched.mobile && formik.errors.mobile,
                      ) && (
                          <FormHelperText error>
                            {formik.errors.mobile}
                          </FormHelperText>
                        )}
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="h4" sx={{ mb: -1, }}>Permission</Typography>
                </Grid>
                <Grid item xs={12}>
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox
                          // id="communityCheckBox"
                          // name="communityCheckBox"
                          onChange={(e) => setCheckCommunity(e?.target?.checked)}
                          checked={checkCommunity}
                          sx={{
                            color: theme?.palette?.inputsLabels?.green,
                            "&.Mui-checked": {
                              color: theme?.palette?.inputsLabels?.green,
                            },
                          }}
                        />
                      }
                      label="Create Community for this Sub-Organization"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          // id="restrictCheckBox"
                          // name="restrictCheckBox"
                          onChange={(e) => setCheckPost(e?.target?.checked)}
                          checked={checkPost}
                          sx={{
                            color: theme?.palette?.inputsLabels?.green,
                            "&.Mui-checked": {
                              color: theme?.palette?.inputsLabels?.green,
                            },
                          }}
                        />
                      }
                      label="Restrict posting to Organization Admins and Primary Contact"
                    />
                  </FormGroup>
                </Grid>
              </Grid>
            )}
            {step === 1 && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12} sx={{ pt: 4, pb: 1.5 }}>
                    <Typography variant="h4">
                      Send Invitation to this contact?
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="body2" sx={{ pb: 1 }}>
                      What's included in the invitation
                    </Typography>
                    <ul>
                      <li>Links to download the iOS or Android app.</li>
                      <li style={{ marginTop: 10, marginBottom: 10 }}>
                        Temp password so they can login. (Note they will need to
                        reset their password right away).
                      </li>
                      <li>Welcome information.</li>
                    </ul>
                  </Grid>
                </Grid>
              </>
            )}
            {step === 2 && (
              <>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <SuccessPopup
                      title={"Sub-Organization"}
                      message={"Success! Contact information has been sent."}
                      open={() => setOpen(true)}
                      handleClose={() => {
                        formik.resetForm();
                        setCheckCommunity(false);
                        setCheckPost(false);
                        handleClose(false);
                      }}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
          <DialogActions sx={{ pr: 4, pb: 3.5 }}>
            {step === 0 && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ paddingX: 3.5, paddingY: 1.2 }}
                  color="inherit"
                  onClick={() => handleClose(true)}
                >
                  Close
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  type="submit"
                  sx={{ paddingX: 3.5, paddingY: 1.2, pl: 4 }}
                >
                  Next
                </Button>
              </>
            )}
            {step === 1 && (
              <>
                <Button
                  size="small"
                  variant="contained"
                  sx={{ mr: 1, paddingX: 3.5, paddingY: 1.2 }}
                  color="inherit"
                  onClick={() => setStep(2)}
                >
                  Save, but don't send
                </Button>
                <Button
                  size="small"
                  variant="contained"
                  // type="submit"
                  sx={{ paddingX: 3.5, paddingY: 1.2 }}
                  onClick={() => setStep(2)}
                >
                  Yes, Send
                </Button>
              </>
            )}
            {step === 2 && (
              <>
                <Button
                  variant="outlined"
                  onClick={() => {
                    formik.resetForm();
                    handleClose();
                  }}
                >
                  Close
                </Button>
              </>
            )}
          </DialogActions>
        </form>
      </Dialog>
      {showDeletePopup && (
        <DeleteConfirmPopup
          title={"Sub-Organization"}
          message={"Are you sure, want to delete this sub-organization?"}
          open={showDeletePopup}
          handleClose={() => {
            setShowDeletePopup(false);
            setSelectedDataGridRow("");
          }}
          handleConfirm={handleConfirmDelete}
        />
      )}
      {showCancelSaveConfirmPopup && (
        <CancelConfirmDialog
          title={"Sub-Organization"}
          message={"Are you sure you want to close without saving?"}
          open={showCancelSaveConfirmPopup}
          handleClose={() => setShowCancelSaveConfirmPopup(false)}
          handleCancel={() => {
            setShowCancelSaveConfirmPopup(false);
            setOpen(true);
          }}
          handleConfirmClose={() => {
            setShowCancelSaveConfirmPopup(false);
            setSelectedDataGridRow(undefined);
            formik.resetForm();
            setCheckCommunity(false);
            setCheckPost(false);
          }}
        />
      )}
      {showSuccessPopup && (
        <SuccessPopup
          title={"Sub-Organization"}
          message={"Success! Your actions have been successfully implemented."}
          open={showSuccessPopup}
          handleClose={() => setShowSuccessPopup(false)}
        />
      )}
    </>
  );
};

export default SubOrganizations;

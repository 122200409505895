import { Box, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../node_modules/react-quill/dist/quill.snow.css";
// import { CALENDAR_PAGE } from '../../../../routes/constants';
// import { useNavigate } from 'react-router';
import EditEvent from "../EditEvent";

export default function CreateCampStep5({
  // onNext,
  onBack,
  // step,
  handleCancel,
  setShowCancelConfirmPopup,
}) {
  // const ITEM_HEIGHT = 35;
  // const ITEM_PADDING_TOP = 5;
  // const MenuProps = {
  //   PaperProps: {
  //     style: {
  //       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
  //       width: 300,
  //     },
  //   },
  // };

  // const navigate = useNavigate();
  const theme = useTheme();
  const state = useSelector((state) => state);
  const orgDetails = state?.org?.org?.data;
  const locationsList = state?.event?.locations ?? [];
  const campEventDetails = state?.createCampEvent;
  const dispatch = useDispatch();
  let locations = [];
  if (locationsList?.length && campEventDetails?.step2Details?.locations) {
    locationsList?.forEach((loc) => {
      campEventDetails?.step2Details?.locations?.forEach((locationId) => {
        if (loc?.id === locationId) {
          locations.push({ lat: loc?.latitude, lng: loc?.longitude });
        }
      });
    });
  }

  let reviewData = {
    title: campEventDetails?.step1Details?.campName,
    description: campEventDetails?.step1Details?.description,
    eventDates: [campEventDetails?.step1Details?.startDate, campEventDetails?.step1Details?.endDate],
    startTime: campEventDetails?.step1Details?.startTime,
    endTime: campEventDetails?.step1Details?.endTime,
    cost: campEventDetails?.step3Details?.cost,
    locations: locations ?? [],
    visibilty: campEventDetails?.step4Details?.permissions?.length
      ? campEventDetails?.step4Details?.permissions[
          campEventDetails?.step4Details?.permissions?.length - 1
        ]
      : "",
    attendees: campEventDetails?.step3Details?.attendees,
    timezone: campEventDetails?.step1Details?.timezone,
    color: campEventDetails?.step3Details?.color,
    dateSelectionOpt: campEventDetails?.step1Details?.dateSelectionOpt,
    total_players: 0,
  };

  const renderSelectedLocations = () => {
    if (
      campEventDetails?.step2Details?.locations?.length &&
      locationsList?.length
    ) {
      let counter = 0;
      return locationsList?.map((location, index) => {
        if (campEventDetails?.step2Details?.locations?.includes(location?.id)) {
          counter++;
          return (
            <Box
              key={location?.id}
              display={"flex"}
              alignItems={"center"}
              my={1}
            >
              <Box
                sx={{
                  background: orgDetails?.secondary_color
                    ? orgDetails?.secondary_color
                    : theme?.palette?.dark?.dark,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "white",
                  width: 40,
                  height: 40,
                  minWidth: 40,
                  minHeight: 40,
                  borderRadius: "100%",
                  fontSize: 16,
                  fontWeight: 600,
                }}
              >
                {counter}
              </Box>
              <Typography
                variant="h6"
                sx={{
                  color: theme?.palette?.dark?.darkTextSubHeader,
                  fontSize: 15,
                  fontFamily: "Poppins",
                  fontWeight: 500,
                  ml: 2,
                }}
              >
                {location?.name ? `${location?.name} -` : null} &nbsp;
                {`${location?.address ? location?.address + ", " : ""}
                  ${location?.suit ? location?.suit + ", " : ""}
                  ${location?.state ? location?.state + ", " : ""}
                  ${location?.city ? location?.city + ", " : ""}
                  ${location?.zip_code ? location?.zip_code : ""}
                  `}
              </Typography>
            </Box>
          );
        } else {
          return null;
        }
      });
    }
  };

  return (
    <Box
      p={3}
      mt={1}
      display={"flex"}
      flexDirection={"column"}
      alignItems={"center"}
    >
      <Typography
        variant="h6"
        sx={{
          color: theme?.palette?.dark?.darkTextSubHeader,
          fontSize: 15,
          fontFamily: "Poppins",
          fontWeight: 500,
          mb: 2,
        }}
      >
        Review your event and publish!
      </Typography>
      <Box
        mt={3}
        sx={{
          maxWidth: "500px",
          width: "100%",
          m: "0 auto",
        }}
      >
        <EditEvent formData={reviewData} hideCloseDeleteBtn={true} />
        <Box className="event-top-box">
          <h3>Additional Locations</h3>
          {renderSelectedLocations()}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        width={1}
        mt={8}
      >
        <Typography
          variant="h5"
          sx={{
            maxWidth: 120,
            height: 40,
            background: "transparent",
            border: `2px solid ${theme?.palette?.secondary?.dark}`,
            borderRadius: "5px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: theme?.palette?.secondary?.dark,
            cursor: "pointer",
            mr: 2,
            my: 1,
            px: 3,
          }}
          onClick={() => setShowCancelConfirmPopup(true)}
        >
          Cancel
        </Typography>
        <Box display="flex" alignItems="center" flexWrap="wrap">
          <Typography
            variant="h5"
            sx={{
              maxWidth: 120,
              height: 40,
              background: "transparent",
              border: `2px solid ${theme?.palette?.secondary?.dark}`,
              borderRadius: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: theme?.palette?.secondary?.dark,
              cursor: "pointer",
              px: 3,
              my: 1,
            }}
            onClick={onBack}
          >
            Back
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
